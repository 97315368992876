// Used at UserReviewsSection (Public page)
import React from 'react';

const ArrowRightCircled = ({ width = 48, height = 48, active = false }) => {
    return (
        <svg
        width={width}
        height={height}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24" cy="24" r="24" fill={active ? "#EBF2FE" : "#F5F5F5"}/>
            <path 
                d="M14.371 24C14.371 24.7375 14.9294 25.3333 15.6669 25.3333H28.446L24.0544 29.725C23.5335 30.2458 23.5335 31.0896 24.0544 31.6104C24.3169 31.8708 24.6585 32 25.0002 32C25.3419 32 25.6827 31.8698 25.9427 31.6094L32.6094 24.9427C33.1302 24.4219 33.1302 23.5781 32.6094 23.0573L25.9427 16.3906C25.4219 15.8698 24.5781 15.8698 24.0573 16.3906C23.5365 16.9115 23.5365 17.7552 24.0573 18.276L28.446 22.6667H15.6669C14.9294 22.6667 14.371 23.2625 14.371 24Z"
                fill={active ? "#1160F7" : "#9799A2"}
            />
        </svg>
    );
};

export default ArrowRightCircled;

<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
  <circle cx="24" cy="24" r="24" transform="matrix(-1 0 0 1 48 0)" fill="#EBF2FE"/>
  <path  fill="#1160F7"/>
</svg>