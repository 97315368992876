import { Container } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
    mobileContainer: {
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    container: {
        margin: '0 auto',
        padding: '40px 0 50px'
    }
}));

const FlowContainer = ({ children, small, maxWidth, padding }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = styles();
    if (!maxWidth) maxWidth = small ? 500 : 900;
    const paddingStyle = padding ? padding : null;

    if (small) {
        return (
            <Container
                className={isMobile ? classes.mobileContainer : classes.container}
                style={{ maxWidth: maxWidth, padding: paddingStyle }}
            >
                {children}
            </Container>
        )
    }

    return (
        <Container maxWidth='lg' className={isMobile ? classes.mobileContainer : classes.container} style={{ maxWidth: 900 }}>
            {children}
        </Container>
    )
}

export default FlowContainer;