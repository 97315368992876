import { Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRouter } from "next/router";
import CustomButton from "../CustomButtonsWebsite/CustomButton";
import rbTheme from "../../../../remitbee/theme/Theme";

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    paddingTop: '80px'
  },
  image: {
    maxHeight: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxWidth: '100%',
    [theme?.breakpoints?.down('md')]: {
      position: 'relative',
      width: '100%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '140px 20px 80px 20px',
    width: '500px',
    [theme?.breakpoints?.down('sm')]: {
      padding: '50px 10px 40px 10px',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    maxWidth: '600px',
    margin: "0 80px 0 0",
    [theme?.breakpoints?.up('md')]: {
      margin: '0 50px',
    },
    [theme?.breakpoints?.down('sm')]: {
      textAlign: 'center',
      gap: '40px',
      margin: '0px 70px',
      maxWidth: '100%'
    },
    [theme?.breakpoints?.down('xs')]: {
      margin: '0px',
      maxWidth: '100%'
    },
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '64px',
    lineHeight: '90px',
    color: '#31517A',
    marginBottom: '16px',
    [theme?.breakpoints?.down('sm')]: {
      fontSize: '38px',
      lineHeight: '48px',
    },
  },
  actionTitle: {
    display: 'inline',
    color: '#1160F7',
  },
  description: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '22px',
    color: '#626680',
    [theme?.breakpoints?.down('sm')]: {
      fontSize: '18px',
    },
  },
  button: {
    background: '#2EBC31',
    padding: '16px 24px',
    fontSize: '16px',
    color: '#fff',
    minWidth: '130px',
    maxWidth: 'fit-content',
    [theme?.breakpoints?.down('xs')]: {
      width: '100%',
      maxWidth: '100%'
    },
  },
  images: {
    width: '100%',
    position: 'relative',
    [theme?.breakpoints?.down('sm')]: {
      padding: '0 0 0 30px',
    },
  },
}))


export default function HeroFullImageSection({ title, herotitle, description, actionText, actionUrl, image, actionType }) {

  const classes = useStyles();
  const router = useRouter();
  const isMobile = useMediaQuery(`(max-width: ${rbTheme.breakpoints.sm}px)`);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={7} className={classes.content}>
        <div className={classes.contentContainer}>
          <div>
            <Typography variant="h1" className={classes.title}>
              {title} {herotitle && <span className={classes.actionTitle}>{herotitle}</span>}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {description}
            </Typography>
          </div>
          <CustomButton
            type={actionType}
            onClick={() => router.push(actionUrl)}
            className={classes.button}
          >
            {actionText}
          </CustomButton>
        </div>
      </Grid>
      <Grid item xs={12} md={5} className={classes.images}>
        <img src={image} alt="Placeholder" className={classes.image} />
      </Grid>
    </Grid>
  );
}
