import React, { useEffect, useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import InfiniteScroll from 'react-infinite-scroller';
import Box from '../../../../remitbee/components/box/Box';
import Typography from '../../../../remitbee/components/typography/Typography';
import theme from '../../../../remitbee/theme/Theme';
import classes from '../../InternationalBills.module.scss'
import { snackbar } from '../../../../remitbee/components/snackbar/SnackBar';
import Spacing from '../../../../remitbee/styles/spacing/Spacing';


const ServicesCards = ({ plans, onSelect, selectedPlan, productQuery, loadMorePayload, customScroll }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if(plans) setProducts(plans)
  }, [plans]);

  const loadMoreProducts = async () => {
    try {
      const { data, success } = await productQuery({
        ...loadMorePayload,
        pageOptions: { currentPage: currentPage + 1, rowsPerPage: 10 }
      }
      );
      if (success && data && data.getProducts) {
        const newProducts = data.getProducts.products;
        if (newProducts.length > 0) {
          setProducts([
            ...products,
            ...newProducts
          ]);

          setCurrentPage(currentPage + 1);
          if(newProducts.length < 10) {
            setHasMore(false)
          }
        } else setHasMore(false);
      }
    } catch (error) {
      snackbar.error('Something went wrong', 3000);
    }
  };

  if (products.length < 1) return null
  return (
    <div className={`${classes['rb-service-cards-wrapper']}`}>
      <InfiniteScroll
        className={classes['rb-services-container']}
        pageStart={0}
        loadMore={loadMoreProducts}
        hasMore={hasMore}
        useWindow={customScroll}
        loader={products.length > 9 && [...new Array(2)].map((_, i) => <Skeleton variant="rectangular" width={'calc(50% - 8px)'} key={i} height={160} />)}
      >
        {products && products.length > 0 && products.map((plan) => (
          <Box onClick={() => onSelect && onSelect(plan)} key={plan.id} className={`${classes['rb-card-container']} ${selectedPlan?.id === plan.id ? classes['rb-card-selected-container'] : ''}`}>
            <Box className={classes.cardContent}>
              <Box
                className={`${classes['rb-card-top-section']} ${selectedPlan?.id === plan.id ? classes['rb-card-top-section-selected'] : ''}`}
              >
                <Box display='flex' alignItems='flex-end' style={{ gap: '5px'}}><Typography variant="h3" weight='semibold' color={theme.palette.primary.navy}>{`$${plan.retailAmount} `}</Typography><Typography variant='body1' color={theme.palette.primary.navy}>{plan.retailAmountUnit}</Typography></Box>
                <input
                  checked={selectedPlan?.id === plan.id}
                  type='radio'
                />
              </Box>
              <Box className={classes['rb-card-bottom-section']}>
                <Typography variant="h4" color={theme.palette.primary.navy} weight='semibold'>{plan.name}</Typography>
                <Spacing variant='betweenButtons' />
                {plan.description && plan.description.split(',').map(detail => (
                  <Box display='flex'>
                    <img src='/img/list-icon.svg' />
                    <Box flexGrow={1} pl={1} >
                      <Typography variant='body1' className={classes.serviceHelpText}>{detail}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default ServicesCards;