import React from 'react'

const Share = ({ height = 20, width = 20, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M6.53333 11.2596L12.225 14.5763M12.2167 5.4263L6.53333 8.74297M16.875 4.16797C16.875 5.54868 15.7557 6.66797 14.375 6.66797C12.9943 6.66797 11.875 5.54868 11.875 4.16797C11.875 2.78726 12.9943 1.66797 14.375 1.66797C15.7557 1.66797 16.875 2.78726 16.875 4.16797ZM6.875 10.0013C6.875 11.382 5.75571 12.5013 4.375 12.5013C2.99429 12.5013 1.875 11.382 1.875 10.0013C1.875 8.62059 2.99429 7.5013 4.375 7.5013C5.75571 7.5013 6.875 8.62059 6.875 10.0013ZM16.875 15.8346C16.875 17.2153 15.7557 18.3346 14.375 18.3346C12.9943 18.3346 11.875 17.2153 11.875 15.8346C11.875 14.4539 12.9943 13.3346 14.375 13.3346C15.7557 13.3346 16.875 14.4539 16.875 15.8346Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Share