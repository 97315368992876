import { makeStyles } from '@mui/styles';
import React from 'react';

const styles = makeStyles(() => ({
    bigUnderline: {
        marginBottom: '30px',
        "@media (max-width: 959px)": {
            marginBottom: '20px',
        },
    },
    logout: {
        marginTop: '10px', 
        marginBottom: '20px'
    }
}))

function CustomUnderlineMediumHeding({ color, type, page }) {
    const classes = styles()
    return (
        <div>
            <svg xmlns="https://www.w3.org/2000/svg" width="127.943" height="3" viewBox="0 0 127.943 3" className={page ==='logout' ? classes[type] + " " + classes.bigUnderline + " " + classes.logout : classes[type] + " " + classes.bigUnderline} >
                <g id="Group_38003" data-name="Group 38003" transform="translate(-189.999 1424.743)">
                    <g id="Group_38002" data-name="Group 38002" transform="translate(191.499 -1423.243)">
                        <path id="Path_39598" data-name="Path 39598" d="M191.5-1401.243h93.9" transform="translate(-191.499 1401.243)" fill="none" stroke={[color]} strokeLinecap="round" strokeWidth="3" />
                        <path id="Path_39599" data-name="Path 39599" d="M272.358-1423.243H286.92" transform="translate(-161.977 1423.243)" fill="none" stroke={[color]} strokeLinecap="round" strokeWidth="3" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default CustomUnderlineMediumHeding;