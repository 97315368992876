import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setProductAmount, setSelectedProduct } from '../../../../redux/mobileTopUp/actions';
import ServicesCards from '../../../internationalBills-v2/ProviderSelection/components/ServiceCards';

const FixedProducts = ({
    selectedOperator,
    onSelect,
    selected,
    products,
    setProductAmount,
    mobileTopUpProducts,
    type,
    subServiceId,
    setSelectedProduct
}) => {
    const [selectedPlan, setSelectedPlan] = useState(selected || {});

    const handlePlanSelection = (product) => {
        setSelectedPlan(product);
        setProductAmount(product?.destinationAmount);
        setSelectedProduct(product)
        if (onSelect) onSelect(product);
    }

    if ((products && !products.length && !type) || !products) return null

    return (
      <ServicesCards
        operator={selectedOperator}
        selectedPlan={selectedPlan}
        onSelect={handlePlanSelection}
        plans={products[type]}
        customScroll={true}
        loadMorePayload={{
          type: 'FIXED_VALUE_RECHARGE',
          subServiceId,
          operatorId: +selectedOperator?.id,
        }}
        productQuery={mobileTopUpProducts}
      />
    );
};

const mapStateToProps = ({ mobileTopUp })=> ({
    products: mobileTopUp.products,
    selected: mobileTopUp.selectedProduct
})

export default connect(mapStateToProps, { setProductAmount, setSelectedProduct })(FixedProducts);