import React from 'react';

const Network = ({ width, height, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 21" fill="none">
      <path
        d="M10.0044 10.5013V10.5116M12.949 7.58515C13.7299 8.35864 14.1685 9.40758 14.1685 10.5013C14.1685 11.595 13.7299 12.644 12.949 13.4175M15.8947 4.66797C16.6683 5.434 17.2819 6.34342 17.7005 7.34431C18.1192 8.3452 18.3346 9.41794 18.3346 10.5013C18.3346 11.5847 18.1192 12.6574 17.7005 13.6583C17.2819 14.6592 16.6683 15.5686 15.8947 16.3346M7.05564 13.4175C6.27483 12.644 5.8362 11.595 5.8362 10.5013C5.8362 9.40758 6.27483 8.35864 7.05564 7.58515M4.10789 16.3346C3.33434 15.5686 2.72073 14.6592 2.30209 13.6583C1.88344 12.6574 1.66797 11.5847 1.66797 10.5013C1.66797 9.41794 1.88344 8.3452 2.30209 7.34431C2.72073 6.34342 3.33434 5.434 4.10789 4.66797"
        stroke={color}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Network;
