import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import ServicesCards from '../../../internationalBills-v2/ProviderSelection/components/ServiceCards';
import CurrencyInput from '../../../common/currencyInput/CurrencyInput';
import { setConversionLoading, setErrorMessage, setProductAmount, setSelectedProduct } from '../../../../redux/mobileTopUp/actions';
import { convertToCAD, setTransferAmountDT } from '../../../../redux/services/actions';
import FlagIcon from '../../../common/FlagIcon';
import { snackbar } from '../../../../remitbee/components/snackbar/SnackBar';

const useStyles = makeStyles(() => ({
  alertMessage: {
    color: '#FF4B55',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '3px',
  },
  transferAmountText: {
    fontWeight: 400,
    color: '#31517A',
    fontSize: '16px',
    marginRight: '3px',
  },
  transferAmount: {
    color: '#31517A',
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

const RangedProduct = ({
    selectedOperator,
    products,
    selected,
    onSelect,
    countryCode,
    setProductAmount,
    productAmount,
    errorMessage,
    convertedAmount,
    setTransferAmountDT,
    conversionLoading,
    mobileTopUpProducts,
    setConversionLoading,
    subServiceId,
    activeTab,
    setSelectedProduct,
    setErrorMessage
}) => {
    const [selectedPlan, setSelectedPlan] = useState(selected || {});
    const [amount, setAmount] = useState(null);
    const classes = useStyles(); 
    


    useEffect(() => {
      if (products) {
        let product = {};
        if (products.topUpRanged && products.topUpRanged.length) {
          product = products.topUpRanged[0];
          if (selected !== product) {
                    setSelectedPlan(product);
                    setSelectedProduct(product)
                    setProductAmount(null);
                    if (onSelect) onSelect(product);
                } else {
                    setAmount(productAmount);
                }
            }
        }
    }, [products,activeTab]);

    useEffect(() => {
      if(errorMessage !== null) setErrorMessage(null)
        let amountChangeTimer;
        
        clearTimeout(amountChangeTimer);
        if (selectedPlan && selectedPlan.type?.includes('RANGED')) {
            setConversionLoading(true);
    
          amountChangeTimer = setTimeout(() => {
            handleConversion();
          }, 2000);
        }
        return () => {
          clearTimeout(amountChangeTimer);
        };
    }, [productAmount])

    const handleConversion = async () => {
        const enteredAmount = typeof productAmount === 'string' ? parseFloat(productAmount.split(',').join('')) : productAmount;
        const { data } = await convertToCAD(enteredAmount, selectedPlan.id);
        if (data && data.convertCurrencyToCad) {
          setTransferAmountDT(data.convertCurrencyToCad.retailAmount);
          setConversionLoading(false);
        } else {
          snackbar.error("Something went wrong", 3000);
        }
      }

    const handlePlanSelection = (product) => {
        setSelectedPlan(product);
        setSelectedProduct(product)
        setProductAmount(product?.destinationAmount);
        if (onSelect) onSelect(product);
    }

    const handleRangedProduct = (value) => {
        setAmount(value)
        if (onSelect) onSelect(selectedPlan);
        setProductAmount(value); //it will be converted to CAD later
    }

    if (products && products.topUpRanged && !products.topUpRanged.length &&
        products.topUpFixed && products.topUpFixed.length) return (
          <ServicesCards
            operator={selectedOperator}
            selectedPlan={selectedPlan}
            onSelect={handlePlanSelection}
            plans={products.topUpFixed}
            customScroll={true}
            loadMorePayload={{
              type: 'FIXED_VALUE_RECHARGE',
              subServiceId,
              operatorId: +selectedOperator?.id,
            }}
            productQuery={mobileTopUpProducts}
          />
        );

    if (!products || (products.topUpRanged && !products.topUpRanged.length)) return null

    return (
      <>
        <CurrencyInput
          label={`You are paying`}
          value={amount}
          selectable={false}
          editable={true}
          onValueChange={(value) => handleRangedProduct(value)}
          defaultCountryCode={countryCode}
          defaultCountryCurrency={selectedPlan?.destinationUnit}
          inputId={'input1'}
          autoFocus={true}
        />
        {errorMessage && <Typography className={classes.alertMessage}>{errorMessage}</Typography>}
        <Box display="flex" alignItems="center" mt={3}>
          <Box mr={1} display="flex" alignItems="center">
            <FlagIcon code={'CA'} />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.transferAmountText}>Total amount in CAD:</Typography>{' '}
            {conversionLoading ? (
              <Skeleton width={40} height={25} />
            ) : (
              <span className={classes.transferAmount}>{` ${convertedAmount || 0}`}</span>
            )}
          </Box>
        </Box>
      </>
    );
};

const mapStateToProps = ({ mobileTopUp,dt_transaction })=> ({
    products: mobileTopUp.products,
    selected: mobileTopUp.selectedProduct,
    countryCode: mobileTopUp.countryCode,
    productAmount: mobileTopUp.productAmount,
    errorMessage:mobileTopUp.notInRangeMessage,
    conversionLoading: mobileTopUp.conversionLoading,
    convertedAmount:dt_transaction.transfer_amount,
    activeTab: mobileTopUp.activeTab
})

export default connect(mapStateToProps, { setProductAmount, setTransferAmountDT, setConversionLoading, setSelectedProduct, setErrorMessage })(RangedProduct);