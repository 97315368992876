import React from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import Tabs from '../../../../../remitbee/components/tab/Tab'
import classes from './IBANCalculator.module.scss'
import Validator from './Validator'
import Generator from './Generator'

const IBANCalculator = () => {

    let tabContent = [
        {
            label: 'IBAN checker',
            content: <Validator />,
        },
        {
            label: 'IBAN calculator',
            content: <Generator />,
        }
    ];


    return (
        <Box className={classes['rb-calculator-root']} style={{ paddingTop: tabContent.length <= 1 && 0 }}>
            <Tabs hideTableBodySpacing tabContent={tabContent} hideTabHeader={tabContent.length <= 1} dynamicWidth tabHeaderClassName={classes['rb-tabs']} tabLabelClassName={classes['rb-tabs-label']} />
        </Box>
    )
}

export default IBANCalculator