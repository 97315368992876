import React from 'react';
import clsx from 'clsx'
import Box from '../../../../remitbee/components/box/Box';
import Typography from '../../../../remitbee/components/typography/Typography';
import theme from '../../../../remitbee/theme/Theme';
import classes from '../../GiftCardFlowWizard.module.scss'
import useCustomMediaQuery from '../../../../remitbee/customHook';

interface Props {
  selected?: any;
  onChange?: any;
  logo?: string;
  label?: string;
  altImage?: string;
}


const OperatorCard:React.FC<Props> = ({ selected, onChange, logo, label, altImage }) => {
  const isMobile = useCustomMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`)

  return (
    <Box className={classes['rb-operator-card-wrapper']} onClick={onChange}>
      <Box mb={2} className={clsx(classes["rb-card-container"], selected && classes["rb-selected-card"])}>
        <Box className={classes["rb-image-container"]} id='image-container'>
          <img alt={label} src={logo} onError={(e:any) => e.target.src = altImage} />
        </Box>
        <div className={classes["rb-radio-container"]}>
          <input id='radio' type='radio' checked={selected} readOnly />
        </div>
      </Box>
      <Typography variant={isMobile ? 'label1' : 'h5'} weight='semibold' color={theme.palette.primary.navy} id={label? label : 'label'}>{label}</Typography>
    </Box>
  )
}

export default OperatorCard