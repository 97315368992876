import { Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import iPhone13Pro from '../../../../../public/new/landing/iPhone13Pro.png';
import iPhone13ProGiftCard from '../../../../../public/new/landing/iPhone13ProGiftCard.svg';
import AppStoreIcon from '../../../../../public/img/landing/app_store_footer_icon.svg'; 
import PlayStoreIcon from '../../../../../public/img/landing/play_store_footer_icon.svg';

const useStyles = makeStyles((theme) => ({
    downloadAppRoot:{
        flex:1,
        display:"flex",
        justifyContent:'center',
        padding:'80px 0',
        [theme.breakpoints?.down('md')]: {
           padding:0
        },
      },
      downloadAppContainer:{
        borderRadius: "32px",
        position:'relative',
        display:'flex',
        gap:'16px',
        maxWidth:'1160px',
        [theme.breakpoints?.down('md')]: {
            width:'90%',
            borderRadius: "20px",
            flexDirection:'column',
          },
          [theme.breakpoints?.down('xs')]: {
            width:'100%',
            borderRadius:0,
            flexDirection:'column'
          },
      },
      rightSection:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        gap:'32px',
        margin:'80px 64px',
        flex:1,
        [theme.breakpoints?.down('md')]: {
            margin:'60px 14px',
            justifyContent:'flex-start',
          },
      },
      leftSection:{
        flex:1,
        paddingTop:'33px',
        zIndex:100,
        [theme.breakpoints?.down('md')]: {
            margin:'0 auto',
            paddingTop:0
        },
      },
      phoneImage:{
        height:'100%',
        maxWidth:'360px'
      },
      downloadtitle:{
        fontWeight:600,
        fontSize:'36px',
        lineHeight:'48px',
        color:'#fff',
        fontFamily:'Inter'
      },
      downloadDesc:{
        fontFamily:'Inter',
        fontWeight:400,
        fontSize:'16px',
        color:'#fff'
      },
      appIcons: {
        display: 'flex',
        paddingTop: '20px',
      },
      appImage: {
        width: '130px',
        height:'45px',
        paddingRight:'15px',
        boxSizing:'content-box',
        [theme.breakpoints?.down('md')]: {
            width: '150px',
        },
      },
}))




export default function DownloadAppSection({ title, description, backgroundImage, variant }) {

    const classes = useStyles()
    const rootStyle = {};
    rootStyle.background = backgroundImage
      ? `url(${backgroundImage}) no-repeat center`
      : 'linear-gradient(97.75deg, #1160F7 -7.85%, #104FC8 102.18%)';
    rootStyle.backgroundSize = 'cover';
    let iphoneDisplay = iPhone13Pro
    if(variant){
      if(variant==='giftCard') iphoneDisplay = iPhone13ProGiftCard
    }
   
    return (
        <div className={classes.downloadAppRoot}>
        <div className={classes.downloadAppContainer} style={rootStyle}>
            <div className={classes.rightSection}>
                <div className={classes.rightContent}>
                <Typography className={classes.downloadtitle} variant='div'>{title}</Typography>
                <Typography className={classes.downloadDesc} variant='body1'>{description}</Typography>
                </div>
                <div className={classes.appIcons}>
                    <a
                      target="_blanc"
                      href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500"
                      className={classes.appIcon}
                    >
                      <img
                        style={{ paddingRight: '15px' }}
                        src={AppStoreIcon}
                        className={classes.appImage}
                        alt="App store icon"
                        loading="lazy"
                      />
                    </a>
                    <a
                      target="_blanc"
                      href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA"
                      className={classes.appIcon}
                    >
                      <img
                        src={PlayStoreIcon}
                        className={classes.appImage}
                        alt="Play store icon"
                        loading="lazy"
                      />
                    </a>
                  </div>
            </div>
            <div className={classes.leftSection}>
                <img className={classes.phoneImage} src={iphoneDisplay} alt="iphone" />
            </div>
        </div>
      </div>
    )
  }


