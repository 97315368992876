import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import CustomButton from '../components/CustomButtonsWebsite/CustomButton';
import { bigTitle, container } from '../globalStyles';
import { parseArrFromString } from '../../../shared/utility';
import Flag from '../../../remitbee/components/flag/Flag';

const useStyles = makeStyles(theme => ({
  container,
  bigTitle: {
    ...bigTitle,
    marginBottom: '40px !important',
    marginTop: '0px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px !important'
    },
  },
  root: {
    background: "url('/img/landing/map_bg_transparent.png') center no-repeat",
    backgroundSize: '100% auto !important',
    backgroundPosition: 'left top',
    paddingTop: '40px',
    paddingBottom: '60px',
  },
  root2: {
    // background: "url('/img/landing/map_bg_transparent.png') center no-repeat",
    backgroundSize: '100% auto !important',
    backgroundPosition: 'left top',
    paddingTop: '40px',
    paddingBottom: '60px',
  },
  rootWithoutTopPadding: {
    backgroundSize: '100% auto !important',
    backgroundPosition: 'left top',
  },
  rootNoPadding: {
    backgroundSize: '100% auto !important',
    backgroundPosition: 'left top',
  },
  itemsSection: {
    paddingBottom: '40px'
  },
  itemName: {
    color: "#37517A",
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    fontWeight: '500',
  },
  box: {
    textDecoration: 'none',
    backgroundColor: "#fff",
    width: '100%',
    marginBottom: '18px',
    marginLeft: '8px',
    marginRight: '8px',
    borderRadius: '8px',
    padding: '16px',
    border: '1px solid #CACCD5',
    color: '#31517A',
  },
  boxRegional: {
    textDecoration: 'none',
    maxWidth:'112px',
    maxHeight:'112px',
    color: '#31517A',
  },
  imageBox:{
    border: '1px solid #CACCD5',
    borderRadius: '8px',
    objectFit: "cover"
  }
}))

const BoxList = ({title, list, withOutImage, withoutTopPadding= false, isBox= false, noPadding = false , withoutButton = false}) => {
  const classes = useStyles();
  const { t } = useTranslation('landing');
  const [expanded, setExpanded] = useState(false);
  const [fullItemsList] = useState(list && typeof list === 'string' && parseArrFromString(list));
  const [shortItemsList] = useState(list && typeof list === 'string' && parseArrFromString(list).slice(0, 16));
  const [displayCities, setDisplayCities] = useState([]);

  useEffect(() => {
   if(list && typeof list === 'string') {
    setDisplayCities(shortItemsList);
   }else{
    list && setDisplayCities(list.slice(0, 16));
   }
  }, [list])

  const handleClickEvent = () => {
    if (expanded) {
      if( list && typeof list === 'string') {
        setDisplayCities(shortItemsList);
       }else{
        setDisplayCities(list.slice(0, 16));
       }
    } else {
      if( list && typeof list === 'string') {
        setDisplayCities(fullItemsList);
       }else{
        setDisplayCities(list);
       }
    }
    setExpanded(!expanded)
  }

  const expandButton = (
    <CustomButton type="outlinedBlue" onClick={handleClickEvent}>{withoutTopPadding ? 'View all countries' :t('expand_item_list')}</CustomButton>
  ); 
  const collapseButton = (
    <CustomButton type="outlinedBlue" onClick={handleClickEvent}>{t('collapse_section')}</CustomButton>
  );

  const renderItem = (item) => {
    return (
      <Grid item container xs={12} sm={6} md={4} lg={3}>
        <a href={item.link} target="_blank" className={isBox? classes.boxRegional : classes.box}>
          <Grid item container direction="row" xs={12} >
          {item?.iso2 && !isBox && <Flag width={36} height={24} code={item?.iso2}  />}
            <Box pl={1.5}>
            <Grid >
            {item?.iso2 && isBox && <img src={`${process.env.AZURE_COMMON_STORAGE_ENDPOINT}/dtone-region-icons/${item?.iso2}-active.svg`} alt='North America' width={112} height={112} className={classes.imageBox}/>}
              <Typography className={classes.itemName}>{item.name}</Typography></Grid>
            </Box>
          </Grid>
        </a>
      </Grid>
    )
  }

  return (
    <div>
      <Box />
      <Grid container xs={12} justifyContent='center' className={ noPadding ? classes.noPadding : withoutTopPadding ? classes.rootWithoutTopPadding : classnames(classes.container, withOutImage ? classes.root2:  classes.root)}>
        <Box p={!withoutTopPadding && 4} />
        {title && <Typography variant="h2" className={classes.bigTitle}>{title}</Typography>}
        <Grid item container xs={12} className={classes.itemsSection}>
          {displayCities?.map(renderItem)}
        </Grid>
        {!withoutButton &&(expanded ? collapseButton : expandButton)}
      </Grid>
    </div>
  )
}


export default BoxList