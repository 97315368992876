const gql = require('graphql-tag');
const fetch = require("isomorphic-unfetch");
const https = require('https');
const { ApolloClient, InMemoryCache, Observable, createHttpLink } = require('@apollo/client');
const { ApolloLink } = require('@apollo/client/link/core');

const GRAPHQL_URL = (process.env.STRAPI_URL || 'https://cms.remitbee.com') + '/graphql';

const isStagingEnv = process.env.REACT_APP_ENV === 'staging';

const stagingHttpLinkOpts = isStagingEnv ?
    {
        fetchOptions: {
            agent: new https.Agent({
                rejectUnauthorized: false
            })
        }
    }
    : {};

const httpLink = createHttpLink({
    uri: GRAPHQL_URL,
    ...stagingHttpLinkOpts
});

const timeoutLink = new ApolloLink((operation, forward) => {
    return new Observable(observer => {
        const handle = setTimeout(() => {
            observer.error(new Error('Request timeout'));
        }, 12000); // 12 second timeout

        forward(operation).subscribe({
            next: response => {
                clearTimeout(handle);
                observer.next(response);
            },
            error: error => {
                clearTimeout(handle);
                observer.error(error);
            },
            complete: () => {
                clearTimeout(handle);
                observer.complete();
            },
        });
    });
});

const timeoutHttpLink = ApolloLink.from([timeoutLink, httpLink]);

const link = ApolloLink.from([timeoutHttpLink]);

const apolloClientOpts = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    }
};

const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: apolloClientOpts
});

const strapiQuery = (query, variables = {}) => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .query({
                variables,
                query
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: err.message };
                resolve(objToBeReturned);
            });
    });
};

const getBlogData = async () => {
    const BLOG_ARTICLES_QUERY = gql` 
        query blogArticles {
            blogArticles (
                filters: { publishedAt: { notNull: true } },
                pagination: {
                    start: 0,
                    limit: 5000
                }
            ) {
                data {
                    attributes {
                        slug
                        updatedAt
                    }
                }
            }
        }
    `;

    let { success, data } = await strapiQuery(BLOG_ARTICLES_QUERY);
    if (success && data && data.blogArticles) return data.blogArticles;
}

const getHelpData = async () => {
    const HELP_ARTICLES_QUERY = gql` 
        query helpArticles {
            helpCentreArticles (
                filters: {
                    help_centre_group: {
                        help_centre_category: { slug: { notNull: true } }
                    }
                    publishedAt: {
                        notNull: true
                    }
                }
                pagination: {
                    start: 0,
                    limit: 2000
                }
            ) {
                data {
                    attributes {
                        slug
                        updatedAt
                        help_centre_group {
                            data {
                                attributes {
                                    help_centre_category {
                                        data {
                                            attributes {
                                                slug
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    let { success, data } = await strapiQuery(HELP_ARTICLES_QUERY);

    if (success && data && data.helpCentreArticles) return data.helpCentreArticles;
}

const getCurrencyData = async () => {
    const CURRENCY_PAGES_QUERY = gql`
        query currencyPages {
            pages (
                filters: {
                    path: { contains: "currency-converter" },
                    publishedAt: { notNull: true }
                },
                pagination: {
                    start: 0,
                    limit: 500
                }
            ) {
                data {
                    attributes {
                        path
                    }
                }
            }
        }
    `

    let { success, data } = await strapiQuery(CURRENCY_PAGES_QUERY);

    if (success && data && data.pages) return data.pages;
}

const fetchSystemCountries = async () => {
    const isStagingEnv = process.env.REACT_APP_ENV === 'staging';
    let stagingProps = {};
    if (isStagingEnv) {
        stagingProps = {
            agent: new https.Agent({
                rejectUnauthorized: false
            })
        }
    }

    let url = `${process.env.REACT_APP_API}/public-services/online-rates-multi-currency`;
    const config = {
        method: 'GET',
        ...stagingProps,
    };

    try {
        const res = await fetch(url, config);

        if (!res.ok) {
            throw new Error('SSR fetchSystemCountries: Network response was unsuccessful.');
        }

        const data = await res.json();
        return data;
    } catch (error) {
        if (error.code === 'ECONNREFUSED') {
            console.error('SSR fetchSystemCountries: Connection refused.');
            return null;
        } else {
            console.error('SSR fetchSystemCountries: Error occurred during fetch - ', error);
            return null;
        }
    }
}

// Return string without spaces
// const sanitize = input => input.toLowerCase().replace(/ /g, "-").replace(/%20/g, "-")

const getAssetsData = async () => {
    const SITEMAP_PATHS_QUERY = gql` 
        query pages {
            pages (
                filters: {
                    publishedAt: { notNull: true },
                    or: [
                        { path: { ne: "" } },
                        { path: { ne: "index" } }
                    ]
                },
                pagination: {
                    start: 0,
                    limit: 5000
                }
            ) {
                data {
                    attributes {
                        path
                    }
                }
            }
        }
    `;

    let { success, data } = await strapiQuery(SITEMAP_PATHS_QUERY);
    if (success) {
        let paths = data.pages;
        return paths;
    }
    return [];

}

const getLanguageData = async () => {
    const SITEMAP_PATHS_LANGUAGE_QUERY = gql` 
        query getSitemapPathsByLanguage {
            getSitemapPathsByLanguage {
                paths
            }
        }
    `;

    let { success, data } = await strapiQuery(SITEMAP_PATHS_LANGUAGE_QUERY);
    if (success) {
        let paths = data.getSitemapPathsByLanguage.paths;
        return paths;
    }
    return [];

}

const getNewsData = async () => {
    const SITEMAP_NEWS_QUERY = gql` 
        query blogArticlesNews {
            blogArticles (
                filters: {
                    news_sitemap: { eq: true }
                    publishedAt: { notNull: true }
                },
                pagination: {
                    start: 0,
                    limit: 100
                }
            ) {
                data {
                    attributes {
                        slug
                        published_datetime
                        title
                    }
                }
            }
        }
    `;

    let { success, data } = await strapiQuery(SITEMAP_NEWS_QUERY);
    if (success) {
        let paths = data.blogArticles;
        return paths;
    }
    return [];
}

const getSitemapPaths = async () => {
    const SITEMAP_PATHS = gql`
        query sitemap {
            pages (
                filters: { publishedAt: { notNull: true }, },
                pagination: {
                    start: 0,
                    limit: 2000
                }
            ) {
                data {
                    attributes {
                        path
                    }
                }
            }
            helpCentreArticles (
                filters: {
                    help_centre_group: {
                        help_centre_category: { slug: { notNull: true } }
                    }
                    publishedAt: {
                        notNull: true
                    }
                }
                pagination: {
                    start: 0,
                    limit: 2000
                }
            ) {
                data {
                    attributes {
                        slug
                        updatedAt
                        help_centre_group {
                            data {
                                attributes {
                                    help_centre_category {
                                        data {
                                            attributes {
                                                slug
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            blogArticles (
                filters: { publishedAt: { notNull: true } },
                pagination: {
                    start: 0,
                    limit: 2000
                }
            ) {
                data {
                    attributes {
                        slug
                    }
                }
            }
        }
    `;

    const { data, errorMessage } = await strapiQuery(SITEMAP_PATHS);

    if (errorMessage) {
        return {};
    };
    return data;
}

module.exports = {
    getBlogData,
    getHelpData,
    getAssetsData,
    getCurrencyData,
    getLanguageData,
    getNewsData,
    fetchSystemCountries,
    getSitemapPaths
}
