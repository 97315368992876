import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import CustomButton from '../../../../components/landingPage/components/CustomButtonsWebsite/CustomButton';
import { useTranslation } from 'next-i18next';
import { saveClientCookieAcceptance } from '../../../../shared/cookie-handler';
import { container } from '../../globalStyles';

function Copyright() {
  const { t } = useTranslation('landing');
  return (
    <>
      <Typography
        color="inherit"
        href="https://remitbee.com/"
        style={{ fontSize: '12px', fontWeight: '400', color: '#6B708F' }}
      >
        {'© '}
        {new Date().getFullYear()} {t('all_rights')}
      </Typography>{' '}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container,
  root: {
    display: 'flex',
    backgroundColor: 'white',
    padding: '80px 15px 50px 15px',
    marginTop: '60px',
    border: '0.75px solid #E9E9E9',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '80px',
      paddingBottom: '40px',
      marginTop: '0px',
    },
  },
  footerColumn: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '30px',
    },
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  socialMediaIcon: {
    height: '30px',
    width: '30px',
    transition: 'ease-in-out 0.3s',
    '&:hover': {
      transform: 'translateY(-3px)',
    },
  },
  icon: {
    display: 'flex',
    color: '#37517A',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
  list: {
    margin: 0,
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& a': {
      fontSize: '15px',
      color: '#37517A',
      textDecoration: 'none',
    },
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  sectionTitle: {
    fontSize: '17px',
    color: '#37517A',
    fontWeight: '500',
    paddingBottom: '8px',
    marginBottom: '0px!important'
  },
  signatureSection: {
    marginTop: '48px',
    alignItems: 'baseline',
  },
  madeInCa: {
    color: '#37517A',
    fontSize: '14px',
    '& img': {
      position: 'relative',
      top: '6px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '20px',
    },
  },
  appIcons: {
    display: 'flex',
    paddingTop: '20px',
  },
  appImage: {
    width: 'auto',
    height: '30px',
  },
  flagImage: {
    width: 'auto',
    height: '24px',
  },
  copyright: {
    '& p': {
      [theme.breakpoints.up('md')]: {
        float: 'right',
      },
    },
  },
  divider: {
    border: '0.75px solid #E9E9E9',
    opacity: '1',
    width: '100%',
    margin: '40px 0 0 0 !important',
    padding: '0 15px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  },
  cookie: {
    position: 'fixed', 
    bottom: '0',
    left: '0', 
    right: '0', 
    padding: '4px',
    opacity: '1',
    backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '1000',
    '& p': {
      fontFamily: 'Inter',
      color: '#31517A',
      '& img': {
        verticalAlign: 'middle',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        marginBottom: '10px !important'
      },
    },
  },
  closeCookie: {
    marginLeft: '40px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      float: 'right !important',
      marginRight: '10px',
      width: '97px !important',
      minWidth: '97px !important',
      height: '32px !important',
      marginBottom: '10px'
    },
  },
  helper: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation('landing');
  const [acceptance, setAcceptance] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  

  /*
  useEffect(() => {
    const getCookieAcceptance = async () => {
      return await getClientCookieAcceptance()
    }
    let accept = getCookieAcceptance();
    accept.then(result => setAcceptance(result));
  }, [acceptance]);
  */

  const handleAcceptance = () =>{
    const saveCookieAcceptance = async () => {
      return await saveClientCookieAcceptance()
    }
     let accept = saveCookieAcceptance();
     accept.then(result => setAcceptance(result));
  }

  return (
    <div>
      {!acceptance && (
        <div className={classes.cookie}>
          <div>
            <span className={classes.helper}></span>
            <img src='/img/landing/cookie.png' alt="Cookie" loading="lazy" />{t('cookies.text') }
            <a href='/cookies'>{t('cookies.link')}</a>
            {!isMobile && <CustomButton type='outlinedBlue' onClick={handleAcceptance} className={classes.closeCookie}>{t('cookies.close')}</CustomButton>}
          </div> 
          {isMobile && <CustomButton type='outlinedBlue' onClick={handleAcceptance} className={classes.closeCookie}>{t('cookies.close')}</CustomButton>}       
        </div>
      )}
        <Typography component="footer" className={classes.root}>
          <Container className={classes.container}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={6} md={3} className={classes.footerColumn}>
                <Typography variant="body1" className={classes.sectionTitle} marked="left" gutterBottom>
                  {t('company.title')}
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    <a href="/about">{t('company.about')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/remitbee-wallet">{t('company.remitbee_wallet')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/referrals">{t('company.refer')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/blog">{t('company.blog')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/careers">{t('company.carrers')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/send-money-to-india">{t('company.send_money_to_ind')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/send-money-to-sri-lanka">{t('company.send_money_to_sri')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/send-money-to-philippines">{t('company.send_money_to_phil')}</a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.footerColumn}>
                <Typography variant="body1" className={classes.sectionTitle} marked="left" gutterBottom>
                  {t('help.title')}
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    <a href="/help">{t('help.center')}</a>
                  </li>
                  {
                    <li className={classes.listItem}>
                      <a href="/help">{t('help.contact_us')}</a>
                    </li>
                  }
                  <li className={classes.listItem}>
                    <a href="/how-to-pay">{t('help.how_pay')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/our-fees">{t('help.fees')}</a>
                  </li>
                  {/*<li className={classes.listItem}>
                    <Link href="#">Verification</Link>
                </li>*/}
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.footerColumn}>
                <Typography variant="body1" className={classes.sectionTitle} marked="left" gutterBottom>
                  {t('resources.title')}
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    <a href="/terms-and-conditions">{t('resources.terms')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/privacy">{t('resources.privacy')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/security">{t('resources.security')}</a>
                  </li>
                  <li className={classes.listItem}>
                    <a href="/transaction-guarantee-policy">{t('resources.garuntee_policy')}</a>
                  </li>
                </ul>
              </Grid>

              <Grid item xs={12} sm={6} md={3} className={classes.footerColumn}>
                <Typography variant="body1" className={classes.sectionTitle} marked="left" gutterBottom>
                  {t('connect.title')}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  className={classes.iconsWrapper}
                >
                  <Grid xs={12} item className={classes.icons}>
                    <a
                      href="https://www.facebook.com/Remitbee/"
                      target="_blanc"
                      className={classes.icon}
                    >
                      <img
                        className={classes.socialMediaIcon}
                        src="/img/landing/new_facebook_icon.svg"
                        alt="Facebook icon"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://ca.linkedin.com/company/remitbee"
                      target="_blanc"
                      className={classes.icon}
                    >
                      <img
                        className={classes.socialMediaIcon}
                        src="/img/landing/new_linkedin_icon.svg"
                        alt="Linkedin icon"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://twitter.com/remitbee?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                      target="_blanc"
                      className={classes.icon}
                    >
                      <img
                        className={classes.socialMediaIcon}
                        src="/img/landing/new_twitter_icon.svg"
                        alt="Twitter icon"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/remitbee/?igshid=so78ydqsry22"
                      target="_blanc"
                      className={classes.icon}
                    >
                      <img
                        className={classes.socialMediaIcon}
                        src="/img/landing/new_instagram_icon.svg"
                        alt="Instagram icon"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://www.tiktok.com/@remitbee"
                      target="_blanc"
                      className={classes.icon}
                    >
                      <img
                        className={classes.socialMediaIcon}
                        src="/img/landing/new_tiktok_icon.svg"
                        alt="tiktok icon"
                        loading="lazy"
                      />
                    </a>
                  </Grid>
                  <Grid xs={12} item className={classes.appIcons}>
                    <a
                      target="_blanc"
                      href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500"
                      className={classes.appIcon}
                    >
                      <img
                        style={{ paddingRight: '15px' }}
                        src="/img/landing/app_store_footer_icon.svg"
                        width="90px"
                        height="30px"
                        className={classes.appImage}
                        alt="App store icon"
                        loading="lazy"
                      />
                    </a>
                    <a
                      target="_blanc"
                      href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA"
                      className={classes.appIcon}
                    >
                      <img
                        src="/img/landing/play_store_footer_icon.svg"
                        width="102px"
                        height="30px"
                        className={classes.appImage}
                        alt="Play store icon"
                        loading="lazy"
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                variant="middle"
                orientation="horizontal"
                className={classes.divider}
                sx={{
                  opacity: "0.6"
                }} />
            </Grid>
            <Grid container justifyContent="space-between" className={classes.signatureSection}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography className={classes.madeInCa}>
                  {t('made_in')} &nbsp;
                  <img
                    src="/img/landing/styled_canadian_flag_icon.svg"
                    className={classes.flagImage}
                    alt="Canadian flag icon"
                    loading="lazy"
                  />
                  &nbsp; {t('with')} &nbsp;❤️
                </Typography>
              </Grid>
              <Hidden mdDown>
                <Grid item xs={12} sm={6} md={3}></Grid>
              </Hidden>
              <Hidden mdDown>
                <Grid item xs={12} sm={6} md={3}></Grid>
              </Hidden>
              <Grid item xs={12} sm={6} md={3} className={classes.copyright}>
                <Copyright />
              </Grid>
            </Grid>
          </Container>
        </Typography>
    </div>
  );
};

export default Footer;
