import React from 'react';
import classes from './NavigationBar.module.scss';
import Box from '../../components/box/Box';
import Button from '../../components/button/Button';
import NavigationBarMenu from './NavigationBarMenu';
import Language from '../../icons/Language';
import theme from '../../theme/Theme';

type NavigationBarDrawerProps = {
    menus: any;
    handleOpenMenu?: any;
    displayLanguages?: any;
    isPrimaryVariant?: any;
    onChangeLanguage?: any;
    router: any;
};

const NavigationBarDrawer: React.FC<NavigationBarDrawerProps> = ({
    menus,
    handleOpenMenu,
    displayLanguages,
    isPrimaryVariant,
    onChangeLanguage,
    router
}) => {
    return (
        <div className={`${classes['rb-navigationBar-drawer']} ${isPrimaryVariant && classes['rb-navigationBar-primary-drawer']}`}>
            <Box display='flex' flexDirection='column' style={{ height: '100%' }}>
                <Box flexGrow={1} className={classes['rb-navigationBar-drawer-menus']}>
                    <>
                        {menus.map(menu =>
                            <NavigationBarMenu
                                id={menu.id}
                                label={menu.label}
                                onClick={menu.link ? () => handleOpenMenu(menu.link) : null}
                                submenus={menu.submenus}
                                isPrimaryVariant={isPrimaryVariant}
                                isMobile
                            />
                        )}
                        {displayLanguages && <NavigationBarMenu
                            id='language-dropdown'
                            label='EN'
                            isMobile
                            onChangeLanguage={onChangeLanguage}
                            icon={<Language width={20} height={20} color={isPrimaryVariant ? theme.palette.background.white : theme.palette.primary.navy} />}
                            isPrimaryVariant={isPrimaryVariant}
                            submenus={displayLanguages}
                        />}
                    </>
                </Box>
                <Box className={classes['rb-navigationBar-drawer-buttons']}>
                    <Button
                        fullWidth
                        id='sign-up'
                        onClick={() => router.push('/signup')}
                        className={`${isPrimaryVariant && classes['rb-navigationBar-primary-signup-button']}`}
                    >
                        {'Sign up'}
                    </Button>
                    <Button
                        fullWidth
                        id='log-in' 
                        variant={isPrimaryVariant ? 'outlinedWhite' : 'outlined'} 
                        onClick={() => router.push('/login')}
                        >
                        {'Log in'}
                    </Button>
                </Box>
            </Box>
        </div>
    );
};

export default NavigationBarDrawer;
