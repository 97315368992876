import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TabsSection from '../../components/Tabs/Tabs';
import { useRouter } from 'next/router';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    grid: {
      marginTop: theme.spacing(4),
    },
    mobileGrid: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0, 3)
    },
    image: {
      maxWidth: '100%',
      maxHeight: '125px',
    },
    imageMobile: {
      maxWidth: '225px',
      height: '80px',
    },
    clickableImage: {
      cursor: 'pointer'
    }
}));

const Featured = ({ list, secondaryMobileView }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const router = useRouter();
    const getSingleCard = (item, index) => (
      <Grid
        display="flex"
        direction="row"
        alignItems="center"
        container
        justifyContent="center"
        spacing={4}
        className={classes.mobileGrid}
      >
        <Grid item xs={12} sm={4} md={2} key={index}>
          <img
            src={item.image}
            alt={item.alt}
            className={classes.imageMobile}
          />
        </Grid>
      </Grid>
    );
    if (!isMobile && list && list.length > 0)
      return (
        <Grid
          display="flex"
          direction="row"
          alignItems="center"
          container
          justifyContent="center"
          spacing={4}
          className={classes.grid}
        >
          {list.map((item, index) => (
            <Grid item xs={12} sm={4} md={item.size ? item.size - 1 : 2} key={index}>
              <img
                src={item.image}
                alt={item.alt}
                target="_blank"
                onClick={item?.onClick ? () => window?.open(item.onClick, '_blank') : null}
                className={clsx(classes.image, item?.onClick && classes.clickableImage)}
              />
            </Grid>
          ))}
        </Grid>
      );
    else if (isMobile && list && list.length > 0)
      return (
        !secondaryMobileView ?
          <TabsSection
            slider
            data={list && list.length && list.map((item, index) => getSingleCard(item, index))}
          /> :
          <Grid
            display="flex"
            direction="row"
            alignItems="center"
            container
            justifyContent="center"
            spacing={4}
            className={classes.grid}
          >
            {list.map((item, index) => (
              <Grid item xs={6} sm={4} md={item.size ? item.size - 1 : 2} key={index}>
                <img
                  src={item.image}
                  alt={item.alt}
                  onClick={item?.onClick ? () => router.push(item.onClick) : null}
                  className={clsx(classes.image, item?.onClick && classes.clickableImage)}
                />
              </Grid>
            ))}
          </Grid>
      );
    else return null;
};

Featured.propTypes = {
    list: PropTypes.array
};

export default Featured;
