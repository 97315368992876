import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';
import { body1, container, mediumTitle, whiteColor } from '../globalStyles';

const styles = (theme) => ({
  container,
  mediumTitle,
  body1,
  root: {
    display: 'flex',
    backgroundColor: whiteColor,
    textAlign: 'center',
    alignItems: 'flex-start',
    paddingTop: '290px',
    paddingBottom: '100px',
    marginTop: '-390px',
    clipPath: 'polygon(0 4%, 100% 0%, 100% 100%, 0% 100%)',
    zIndex: '0',
    '@media (max-width: 1200px)': {
      clipPath: 'polygon(0 6%, 100% 0%, 100% 100%, 0% 100%)',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '200px',
      paddingBottom: '60px',
      marginTop: '-250px',
      clipPath: 'polygon(0 4%, 100% 0%, 100% 100%, 0% 100%)',
      textAlign: 'center',
      alignItems: 'center',
    },
    '@media (max-width: 480px)': {
      clipPath: 'polygon(0 3%, 100% 0%, 100% 100%, 0% 100%)',
      marginTop: '-160px',
      paddingTop: '140px',
    },
  },
  image: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  imageAlign: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '60px',
    },
  },
  textInfo: {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  bodyTextAlignment: {
    marginBottom: '40px',
  },
  smallTitle: {
    color: '#31517A',
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '20px',
  },
  locationsList: {
    '& ul': {
      listStyleType: 'none',
      textAlign: 'left',
      marginBottom: '30px',
      paddingLeft: '0px !important',
    },
    '& ul li': {
      color: '#6b708f',
      paddingBottom: '10px',
      fontSize: '16px',
    },
  },
});

function CashPickupLocationsList(props) {
  const { classes, title, text, listHeader, lists } = props;

  const item = parseArrFromString(lists);
  return (
    <section className={classes.root}>
      <Grid container className={classes.container + ' ' + classes.containerControl}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h2"
            marked="center"
            className={classes.mediumTitle + ' ' + classes.textInfo}
            component="h2"
          >
            {title}
          </Typography>
          <Typography marked="center" className={classes.body1 + ' ' + classes.bodyTextAlignment}>
            {text}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h2" marked="center" className={classes.smallTitle} component="h2">
            {listHeader}
          </Typography>
          <Grid container className={classes.locationsList}>
            <Grid item xs={6} md={3}>
              <ul>{item.map((item, index) => (index <= 3 ? <li>{item.name}</li> : ''))}</ul>
            </Grid>
            <Grid item xs={6} md={3}>
              <ul>
                {item.map((item, index) =>
                  index > 3 && index < 8 ? (
                    <>
                      <li>{item.name}</li>
                    </>
                  ) : (
                    ''
                  )
                )}
              </ul>
            </Grid>
            <Grid item xs={6} md={3}>
              <ul>
                {item.map((item, index) =>
                  index > 7 && index < 12 ? (
                    <>
                      <li>{item.name}</li>
                    </>
                  ) : (
                    ''
                  )
                )}
              </ul>
            </Grid>
            <Grid item xs={6} md={3}>
              <ul>
                {item.map((item, index) =>
                  index > 11 && index < 15 ? (
                    <>
                      <li>{item.name}</li>
                    </>
                  ) : (
                    ''
                  )
                )}
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}

CashPickupLocationsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CashPickupLocationsList);
