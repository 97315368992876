import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import {
    array, arrayOf, bool, func, node, object, oneOfType, string
} from 'prop-types';
import React from 'react';
import { useTranslation } from 'next-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 2, 1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  arrowBackButtonStyle: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '34px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
});

const DialogTitle = withStyles(styles)(({
  children,
  classes,
  onClose,
  showArrowBack,
  arrowBackOnClickHandler,
  customstyles,
}) => {
  let arrowBackButton = null;
  const { t } = useTranslation('common')
  if (showArrowBack) {
    arrowBackButton = (
      <Button
        aria-label="close"
        className={classes.arrowBackButtonStyle}
        onClick={arrowBackOnClickHandler}
        size="small"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
      >
        <Typography style={{ fontSize: '14px' }}>{t('back')}</Typography>
      </Button>
    );
  }
  return (
    <MuiDialogTitle disableTypography className={classes.root} style={customstyles}>
      {arrowBackButton}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        id="close-dialog"
        color="primary"
      >
        <CloseIcon />
      </IconButton>
      {children}
    </MuiDialogTitle>
  );
});

DialogTitle.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
  classes: oneOfType([object, array]),
  onClose: func.isRequired,
  showArrowBack: bool,
  arrowBackOnClickHandler: func,
};

DialogTitle.defaultProps = {
  showArrowBack: false,
  arrowBackOnClickHandler: null,
};

export default DialogTitle;
