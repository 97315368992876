import {
    SET_COUNTRY_ID,
    SET_COUNTRY_CODE,
    SET_COUNTRY_CALLING_CODE,
    SET_RECIPIENT_PHONE_NUMBER,
    SET_SELECTED_OPERATOR,
    SET_PRODUCTS,
    SET_SELECTED_PRODUCT,
    SET_AVAILABLE_OPERATORS,
    CLEAR_MOBILE_RC_STATE_DT,
    SET_PRODUCT_AMOUNT,
    SET_REQUIRED_FIELDS,
    SET_REQ_FIELD_INFOS,
    SET_ACTIVE_TAB,
    SET_NOT_IN_RANGE_MSG,
    SET_CONVERSION_LOADING,
    SET_DEFAULT_COUNTRY_MOB
} from './actionTypes';
import {
    getOperatorsByPhoneNumber,
    getOperatorsByPhoneNumberPublic,
    getMobileTopUpProductsPublic,
    getMobileTopUpProducts
} from './graphql.graphql';
import { dtoneQuery } from '../../settings/apollo/dtone/query';

export const setCountryId = (payload) => ({
    type: SET_COUNTRY_ID,
    payload,
});

export const setCountryCode = (payload) => ({
    type: SET_COUNTRY_CODE,
    payload,
});

export const setCountryCallingCode = (payload) => ({
    type: SET_COUNTRY_CALLING_CODE,
    payload,
});

export const setRecipientPhoneNumber = (payload) => ({
    type: SET_RECIPIENT_PHONE_NUMBER,
    payload,
});

export const setSelectedOperator = (payload) => ({
    type: SET_SELECTED_OPERATOR,
    payload,
});

export const setProducts = (payload) => ({
    type: SET_PRODUCTS,
    payload,
});

export const setSelectedProduct = (payload) => ({
    type: SET_SELECTED_PRODUCT,
    payload,
});

export const setAvailableOperators = (payload) => ({
    type: SET_AVAILABLE_OPERATORS,
    payload,
});

export const clearMobileFlowState = (payload) => ({
    type: CLEAR_MOBILE_RC_STATE_DT,
    payload
})

export const setRequiredFields = (payload) => ({
    type: SET_REQUIRED_FIELDS,
    payload,
});

export const setReqFieldInfo = (payload) => ({
    type: SET_REQ_FIELD_INFOS,
    payload
})

export const setErrorMessage = (payload) => ({
    type:SET_NOT_IN_RANGE_MSG,
    payload
});

export const setConversionLoading = (payload) => ({
    type:SET_CONVERSION_LOADING,
    payload
})

export const setDefaultCountry = (payload) => ({
    type:SET_DEFAULT_COUNTRY_MOB,
    payload
})

export const operatorsByPhoneNumber = (params) => dtoneQuery(getOperatorsByPhoneNumber, params);

export const operatorsByPhoneNumberPublic = (params) => dtoneQuery(getOperatorsByPhoneNumberPublic, params);

export const mobileTopUpProducts = (params) => dtoneQuery(getMobileTopUpProducts, params);

export const mobileTopUpProductsPublic = (params) => dtoneQuery(getMobileTopUpProductsPublic, params);

export const setProductAmount = (payload) => ({
    type: SET_PRODUCT_AMOUNT,
    payload
})

export const setActiveTab = (payload) => ({
    type: SET_ACTIVE_TAB,
    payload
})
