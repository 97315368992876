// Used at UserReviewsSection (Public page)
import React from 'react';

const ArrowLeftCircled = ({ width = 48, height = 48, active = false }) => {
    return (
        <svg
        width={width}
        height={height}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24" cy="24" r="24" fill={active ? "#EBF2FE" : "#F5F5F5"}/>
            <path 
                d="M33.629 24C33.629 24.7375 33.0706 25.3333 32.3331 25.3333H19.554L23.9456 29.725C24.4665 30.2458 24.4665 31.0896 23.9456 31.6104C23.6831 31.8708 23.3415 32 22.9998 32C22.6581 32 22.3173 31.8698 22.0573 31.6094L15.3906 24.9427C14.8698 24.4219 14.8698 23.5781 15.3906 23.0573L22.0573 16.3906C22.5781 15.8698 23.4219 15.8698 23.9427 16.3906C24.4635 16.9115 24.4635 17.7552 23.9427 18.276L19.554 22.6667H32.3331C33.0706 22.6667 33.629 23.2625 33.629 24Z" 
                fill={active ? "#1160F7" : "#9799A2"}
            />
        </svg>
    );
};

export default ArrowLeftCircled;