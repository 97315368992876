import React from 'react';
import Tabs from '../../../../../remitbee/components/tab/Tab';
import MoneyTransferComponent from './MoneyTransferComponent'
import CurrencyExchangeComponent from './CurrencyExchangeComponent'
import Box from '../../../../../remitbee/components/box/Box';
import classes from './MultiConverter.module.scss';

interface Props {
    componentType?: string;
    moneyTransferProps?: any;
    legacyMoneyTransferProps?: any;
}

const MultiConverter: React.FC<Props> = ({ componentType, moneyTransferProps, legacyMoneyTransferProps }) => {
    let tabContent = [
        {
            label: 'Money Transfer',
            content: <MoneyTransferComponent />,
        },
        {
            label: 'Currency Exchange',
            content: <CurrencyExchangeComponent />,
        }
    ];

    switch (componentType) {
        case 'moneyTransfer':
            tabContent = [
                {
                    label: 'Money Transfer',
                    content: <MoneyTransferComponent {...moneyTransferProps} {...legacyMoneyTransferProps} />,
                },
            ];
            break;
        case 'currencyExchange':
            tabContent = [
                {
                    label: 'Currency Exchange',
                    content: <CurrencyExchangeComponent />,
                },
            ];
            break;
    }

    return (
        <Box className={classes['rb-root']} style={{ paddingTop: tabContent.length <= 1 && 0 }}>
            <Tabs hideTableBodySpacing tabContent={tabContent} hideTabHeader={tabContent.length <= 1} dynamicWidth tabHeaderClassName={classes['rb-tabs']} tabLabelClassName={classes['rb-tabs-label']} />
        </Box>
    );
};

export default MultiConverter;