import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { array, bool, func, oneOf, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import StatusIcon from '../../Icons/StatusIcon';
import CustomButton from '../../../landingPage/components/CustomButtonsWebsite/CustomButton';
import { FullColumn } from '../../rowColumn';
import DialogLayout from '../DialogLayout';
import { Box, Divider, Tooltip } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from '../../../../../public/services/copy.svg'
import { calculateDateWith48Hours } from '../../../../shared/utility';

const styles = {
  dialogActionDivStyle: {
    marginBottom: '1em',
  },
  DialogLayoutStyle: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dialogContentTextStyle: {
    textAlign: 'center',
    fontSize: '16px',
    color: '#31517A',
    paddingLeft: '6px',
    paddingRight: '6px',
    fontWeight: 400,
    paddingBottom: '24px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  grid1: {
    maxWidth: '450px'
  },
  grid2: {
    maxWidth: '410px'
  },
  dialogBoxIconStyle: {
    margin: '16px 0 20px 0',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  dialogHeading: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#37517A',
    textAlign: 'center',
  },
  dialogImage: {
    paddingRight: '16px',
    paddingTop: '4px',
  },
  dialogDescription: {
    font: 'Heebo',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '27.2px',
    color: '#37517A',
    paddingBottom: '16px',
  },
  tagTitle: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#1160F7',
  },
  tooltip: {
    padding: "8px 16px",
    background: '#6D6D6D',
    color: '#fff',
    fontSize: '14px',
    lineHeight: '14px',
    boxShadow: '0px 0px 8px #00000040',
    maxWidth: '200px',
    margin: "8px 0"
  },
  tooltipArrow: {
    color: '#6D6D6D'
  },
  serviceType: {
    fontWeight: 500,
    color: '#31517A',
    fontSize: '16px'
  },
  pinStyle: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#626680'
  },
  providerLogo: {
    maxWidth: '75px',
    maxHeight: '30px'
  },
  pinComponentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    border: "1px solid #CACCD5",
    borderRadius: '4px',
  }
};

const StatusDialog = ({
  description,
  title,
  tag,
  variant,
  open,
  status,
  onCloseHandler,
  payment_type,
  confirmActionHandler,
  buttonText,
  settlement_time,
  flow,
  actionType,
  customStyles = {},
  showPinInfoComponent,
  subDescription
}) => {
  const {
    dialogActionDivStyle,
    dialogBoxIconStyle,
    dialogContentTextStyle,
    dialogHeading,
    dialogDescription,
    tagTitle,
    DialogLayoutStyle,
    dialogImage,
    grid1,
    grid2,
  } = styles;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const closed = { serial: false, pin: false };
  const [openTooltip, setOpenTooltip] = useState(closed);

  let DialogStyle = !(payment_type && (payment_type === 'INTERAC_E_TRANSFER' || payment_type === 'BILL_PAYMENT'))
  if (!confirmActionHandler) confirmActionHandler = onCloseHandler;

  const handleCopy = (tooltip) => {
    const tooltips = {
      ...openTooltip,
      [tooltip]: true
    }
    setOpenTooltip(tooltips);

    setTimeout(() => {
      setOpenTooltip(closed);
    }, 1000);
  }

  const dialogTitle = (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      {variant !== 'none' && status && (
        <FullColumn style={dialogBoxIconStyle}>
          {status !== 'error' ? (
            <img src={`/icons/dialog/${status}.svg`}></img>
          ) : (
            <StatusIcon variant={status} />
          )}
        </FullColumn>
      )}
      {tag && <Typography style={{ ...tagTitle, ...customStyles.tagTitle }}>{tag}</Typography>}
      <FullColumn>
        <Typography style={{ ...dialogHeading, ...customStyles.title, width: DialogStyle ? '100%' : isMobile ? '100%' : '450px' }} variant="h3">
          <Markdown children={title} />
        </Typography>
      </FullColumn>
    </Grid>
  );

  const dialogActions = (
    <FullColumn style={dialogActionDivStyle}>
      <CustomButton type={actionType} fullWidth onClick={confirmActionHandler}>{t(buttonText)}</CustomButton>
    </FullColumn>
  );

  return (
    <DialogLayout
      dialogTitleContent={dialogTitle}
      onCloseHandler={onCloseHandler}
      open={open}
      dialogActionContent={dialogActions}
      style={{ ...DialogLayoutStyle, ...customStyles.DialogLayout, maxWidth: isMobile ? '100%' : DialogStyle ? '526px' : '590px', overflow: 'hidden' }}
      fullScreenOnMobile={showPinInfoComponent && isMobile}
    >
      <div data-testid={variant} style={{ ...dialogContentTextStyle }}>
        {description}
      </div>
      {subDescription && <div data-testid={variant} style={{ ...dialogContentTextStyle }}>
        {subDescription}
      </div>}

      {payment_type && (payment_type === 'INTERAC_E_TRANSFER' || payment_type === 'BILL_PAYMENT') && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ ...grid1, ...customStyles.grid }}
        >
          <Box display="flex" justifyContent="center">
            <Box>
              <img
                src="/icons/dialog/mail.svg"
                style={{ ...dialogImage, ...customStyles.image }}
              ></img>
            </Box>
            <Box>
              <Typography style={{ ...dialogDescription, ...customStyles.list, width: isMobile ? '100%' : '412px' }} variant="h3">
                {payment_type === 'INTERAC_E_TRANSFER' ? t(flow + ':dialog.e-transfer_instruction1') : t(flow + ':dialog.bill_payment_instruction1')}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center">
            <Box>
              <img
                src="/icons/dialog/docs.svg"
                style={{ ...dialogImage, ...customStyles.image }}
              ></img>
            </Box>
            <Box>
              <Typography style={{ ...dialogDescription, ...customStyles.list, width: isMobile ? '100%' : '412px' }} variant="h3">
                {payment_type === 'INTERAC_E_TRANSFER' ? t(flow + ':dialog.e-transfer_instruction2') : t(flow + ':dialog.bill_payment_instruction2')}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center">
            <Box>
              <img
                src={flow === 'send-money' ? "/icons/dialog/clock.svg" : flow === 'my-wallet' ? "/icons/dialog/wallet.svg" : "/icons/dialog/clock.svg"}
                style={{ ...dialogImage, ...customStyles.image }}
              ></img>
            </Box>
            <Box>
              <Typography style={{ ...dialogDescription, ...customStyles.list, width: isMobile ? '100%' : '412px' }} variant="h3">
                {payment_type === 'INTERAC_E_TRANSFER' ? t(flow + ':dialog.e-transfer_instruction3') : t(flow + ':dialog.bill_payment_instruction3')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}

      {payment_type && (payment_type === 'EFT' || flow === 'exchange-currency') &&
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ ...grid2, ...customStyles.grid }}
        >
          <Box display="flex" justifyContent="center">
            <Box>
              <img
                src={flow === 'send-money' ? "/icons/dialog/clock.svg" : flow === 'my-wallet' ? "/icons/dialog/wallet.svg" : "/icons/dialog/clock.svg"}
                style={{ ...dialogImage, ...customStyles.image }}
              ></img>
            </Box>
            <Box>
              <Typography style={{ ...dialogDescription, ...customStyles.list }} variant="h3">
                {t(flow + ':dialog.eft_instruction', { completeDate: settlement_time })}
              </Typography>
            </Box>
          </Box>
        </Grid>
      }

      {(showPinInfoComponent && flow === 'escalation') && <Box flexGrow={1} p={'16px'} sx={{ ...styles.pinComponentWrapper }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography style={{ ...styles.pinStyle }}>{'Escalation ID'}</Typography>
          <Typography style={{ ...styles.pinStyle }}>{showPinInfoComponent?.escalationId}</Typography>
        </Box>
        <Divider
          sx={{
            opacity: "0.6"
          }} />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography style={{ ...styles.pinStyle }}>{'Response Date'}</Typography>
          <Typography style={{ ...styles.pinStyle }}>{calculateDateWith48Hours()}</Typography>
        </Box>
      </Box>}

      {(showPinInfoComponent && flow === 'dt_transaction') && <Box flexGrow={1} p={'16px'} sx={{ ...styles.pinComponentWrapper }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography style={{ ...styles.serviceType }}>{showPinInfoComponent.service_type}</Typography>
          <Box alignItems="center">
            <img
              style={{ ...styles.providerLogo }}
              src={showPinInfoComponent.providerLogo}
              alt={showPinInfoComponent.service_type}
            />
          </Box>
        </Box>
        <Divider
          sx={{
            opacity: "0.6"
          }} />
        {showPinInfoComponent.pinSerial && <Box display="flex" justifyContent="space-between" >
          <Typography style={{ ...styles.pinStyle }}>Serial number</Typography>
          <Box>

            <Tooltip
              arrow
              title={'Copied to clipboard'}
              placement='bottom-end'
              open={openTooltip.serial}
              classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
            >
              <CopyToClipboard text={showPinInfoComponent.pinSerial} onCopy={() => handleCopy('serial')}>
                <Box display={'flex'} sx={{ gap: '12px' }}>
                  <Typography style={{ ...styles.pinStyle }}>{showPinInfoComponent.pinSerial}</Typography>
                  <img src={CopyIcon} />
                </Box>
              </CopyToClipboard>
            </Tooltip>
          </Box>
        </Box>}
        {showPinInfoComponent.pinCode && <Box display="flex" justifyContent="space-between">
          <Typography style={{ ...styles.pinStyle }}>PIN code</Typography>
          <Box>

            <Tooltip
              arrow
              title={'Copied to clipboard'}
              placement='bottom-end'
              open={openTooltip.pin}
              classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
            >
              <CopyToClipboard text={showPinInfoComponent.pinCode} onCopy={() => handleCopy('pin')}>
                <Box display={'flex'} sx={{ gap: '12px' }}>
                  <Typography style={{ ...styles.pinStyle }}>{showPinInfoComponent.pinCode}</Typography>
                  <img src={CopyIcon} />
                </Box>
              </CopyToClipboard>
            </Tooltip>
          </Box>
        </Box>}
      </Box>}
    </DialogLayout>
  );
};

StatusDialog.propTypes = {
  description: oneOfType([string, array]),
  title: string,
  tag: string,
  payment_type: string,
  open: bool,
  status: string,
  settlement_time: string,
  onCloseHandler: func,
  confirmActionHandler: func,
  buttonText: string,
  actionType: string,
  variant: oneOf(['success', 'warning', 'error', 'none']),
};

StatusDialog.defaultProps = {
  variant: 'success',
  open: false,
  status: 'success',
  tag: '',
  confirmActionHandler: null,
  buttonText: 'Continue',
  description: null,
  actionType: 'blue'
};

export default StatusDialog;
