import React from 'react'

const Sandwich = ({ height = 32, width = 32, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 16H28M4 8H28M4 24H20" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Sandwich