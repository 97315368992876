import React from 'react';

const ESim = ({ height = 28, width = 28, color = "#1160F7" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28" fill="none">
      <path
        d="M15.3346 7L15.3346 9.33333M19.3346 7V9.33333M11.3346 8.16667V9.33333M7.33464 24.5L20.668 24.5C21.3752 24.5 22.0535 24.2542 22.5536 23.8166C23.0537 23.379 23.3346 22.7855 23.3346 22.1667L23.3346 5.83333C23.3346 5.21449 23.0537 4.621 22.5536 4.18342C22.0535 3.74583 21.3752 3.5 20.668 3.5L12.4386 3.5C11.7314 3.50013 11.0533 3.74605 10.5533 4.18367L5.4493 8.64967C4.94917 9.08715 4.66812 9.68055 4.66797 10.2993L4.66797 22.1667C4.66797 22.7855 4.94892 23.379 5.44902 23.8166C5.94911 24.2542 6.62739 24.5 7.33464 24.5Z"
        stroke={color}
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ESim;
