import React from 'react';

const TextArrowRight = () => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_5531_54673)">
        <path
          d="M4.1665 9.99805H15.8332"
          stroke="#31517A"
          strokeWidth="1.45833"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 13.3314L15.8333 9.99805"
          stroke="#31517A"
          strokeWidth="1.45833"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 6.66602L15.8333 9.99935"
          stroke="#31517A"
          strokeWidth="1.45833"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5531_54673">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TextArrowRight;
