import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import {
    arrayOf, bool, func, oneOf, shape, string
} from 'prop-types';
import React, { forwardRef } from 'react';
import FlagIcon from '../../../FlagIcon';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(1.5, 3),
    '&:hover': {
      background: '#E9F5FF'
    }
  },
  label: {
    fontSize: '16px',
    lineHeight: '18.75px'
  },
  labelName: {
    marginLeft: theme.spacing(2),
    fontSize: '16px',
    lineHeight: '18.75px'
  }
}));

const CountryListItem = forwardRef(({
  onItemClick,
  item,
  options,
  hasFlagIcon,
}, ref) => {
  const classes = useStyles();
  let content = '';
  let currencyCode = '';
  options.forEach((text) => {
    if (text === 'currency_code') {
      currencyCode = item[text];
    } else content += `${item[text]} `;
  });
  let flagIcon = <Grid item xs={1} />;
  if (hasFlagIcon) {
    flagIcon = (
      <Grid item xs={1}>
        <Box style={{ height: '100%' }} display="flex" alignItems="center">
          <FlagIcon code={item.iso2} size={22} />
        </Box>
      </Grid>
    );
  }
  return (
    <ListItem
      button
      ref={ref}
      onClick={() => onItemClick(item)}
      className={classes.item}
    >
      <Grid container>
        <Grid item container direction="row" alignItems="center" className={classes.line}>
          {flagIcon}
          <Typography className={classes.labelName} variant={"h4"}>{content}</Typography>
          <Box flexGrow={1} />
          <Typography variant={"h4"} className={classes.label}>{currencyCode}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
});


CountryListItem.propTypes = {
  onItemClick: func.isRequired,
  item: shape({
    iso2: string,
    currency_code: string,
    currency_name: string,
  }),
  hasFlagIcon: bool,
  options: arrayOf(
    oneOf(['currency_code', 'currency_name', 'country_to']),
  ),
};

CountryListItem.defaultProps = {
  item: {
    iso2: 'CA',
    currency_code: 'CAD',
    currency_name: 'Canadian dollar',
  },
  hasFlagIcon: true,
  options: ['currency_code', 'currency_name'],
};

export default CountryListItem;
