import { apiMainQuery } from "../../settings/apollo/api/main/query";
import { dtoneQuery } from "../../settings/apollo/dtone/query";
import { SET_PLANS_DETAILS, SET_SELECTED_DESTINATION_TYPE, SET_COUNTRIES_DETAILS, SET_SELECTED_DESTINATION_REGION, SET_SELECTED_PLAN_ESIM, SET_RECEIVING_EMAIL_ESIM, CLEAR_ESIM_STATE, SET_COMPATIBILITY_DEVICES_ESIM } from "./actionTypes";
import { getEsimCompatibleDevices, GetCountries, getCountriesPublic ,getEsimProductsPublic} from './graphql.graphql'

export const setSelectedDestinationType = (payload) => ({
    type: SET_SELECTED_DESTINATION_TYPE,
    payload
})

export const setCountriesDetails = (payload) => ({
    type: SET_COUNTRIES_DETAILS,
    payload
}) 

export const setSelectedDestinationRegion = (payload) => ({
    type: SET_SELECTED_DESTINATION_REGION,
    payload
})

export const setPlansDetails = (payload) => ({
    type: SET_PLANS_DETAILS,
    payload

});

export const setSelectedPlanState = (payload) => ({
    type: SET_SELECTED_PLAN_ESIM,
    payload
})

export const setReceivingEmailState = (payload) => ({
    type: SET_RECEIVING_EMAIL_ESIM,
    payload
})

export const clearESimState = () => ({
    type: CLEAR_ESIM_STATE
})

export const setCompatibilityDevicesState = (payload) => ({
    type: SET_COMPATIBILITY_DEVICES_ESIM,
    payload
})

export const getAllCompatibilityDevices = () => dtoneQuery(getEsimCompatibleDevices, { platform: 'WEB'});

export const getCountriesByType = (destionationType, pageOptions = { pagination : { currentPage: 1, rowsPerPage: 500 } }) => dtoneQuery(GetCountries, { destionationType, pageOptions })

export const getCountriesPublicByType = (destionationType, pageOptions = { pagination : { currentPage: 1, rowsPerPage: 500 } }) => dtoneQuery(getCountriesPublic, { destionationType, pageOptions })

export const esimProductsPublic = (countryId, operatorId,serviceId, type, pageOptions = { currentPage: 1, rowsPerPage: 10 } ) => dtoneQuery(getEsimProductsPublic, {countryId, operatorId,serviceId, type, pageOptions });