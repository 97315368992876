import React from 'react'

const Exchange = ({ height = 20, width = 20, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M14.167 15.7289C15.9348 14.4409 17.0837 12.3547 17.0837 10C17.0837 6.08802 13.9123 2.91671 10.0003 2.91671H9.58366M10.0003 17.0834C6.08831 17.0834 2.91699 13.9121 2.91699 10C2.91699 7.64543 4.06588 5.55915 5.83366 4.27121M9.16699 18.6667L10.8337 17L9.16699 15.3334M10.8337 4.66671L9.16699 3.00004L10.8337 1.33337" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Exchange