import React, { useCallback } from 'react'
import classes from './ConverterGraph.module.scss'
import theme from '../../../../../remitbee/theme/Theme'
import Typography from '../../../../../remitbee/components/typography/Typography'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';

const styles = {
    label: {
            fill: theme.palette.primary.navy,
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '18px' 
    }
}

const CustomTooltip:React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes['rb-graph-tooltip']}>
        <Typography variant='textSm' color={theme.palette.primary.navy} weight='semibold'>{payload[0].value}</Typography>
        <Typography variant='textXs'>{label}</Typography>
       </div>
      )
}
}

const Chart = ({ ratesHistory, setRatesHistory, lastPosition, setLastPosition }) => {

    const formatData = ratesHistory.map(item => ({
        name: item.datetime_added,
        rate: parseFloat(item.rate).toFixed(2)
    }));

    // const handleMouseMove = useCallback((state) => {
    //     if (state.isTooltipActive) {
    //       const { activePayload } = state;
    //       if (activePayload && activePayload.length && (lastPosition?.name !== activePayload[0].payload.name)) {
    //         setLastPosition({
    //           name: activePayload[0].payload.name,
    //           rate: activePayload[0].payload.rate,
    //         });
    //       }
    //     }
    //   }, [lastPosition]);
    

    const formatXAxis = (tickItem) => {
        const date = new Date(tickItem);
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    };

    const formatYAxis = (data) => {
        if(!data || (data && data.length === 0)) return ['auto', 'auto']
        const padding = 0.5;
        const formattedData = data.map(data => parseFloat(data.rate));
        const minRate = Math.min(...formattedData);
        const maxRate = Math.max(...formattedData);

        return [minRate - padding, maxRate + padding]
    }

    

    return (
    <ResponsiveContainer width="100%" height={400}>
        <AreaChart
            data={formatData}
            // onMouseMove={handleMouseMove}

        >
            <CartesianGrid strokeDasharray="1 1" fill='#FFF' />
            <XAxis tickFormatter={formatXAxis} tick={styles.label} dataKey="name" strokeDasharray={1} fill='#FFF' strokeOpacity={0.1} />
            <YAxis tick={styles.label} domain={formatYAxis(ratesHistory)} strokeDasharray={1} fill='#FFF' strokeOpacity={0.1} />
            <Tooltip content={<CustomTooltip />} />
            <Area type="monotone" dataKey="rate" stroke="#008000" strokeWidth={'3px'} fill="rgba(0, 128, 0, 0.07)"
                activeDot={{ r: 4, fill: theme.palette.primary.blue, stroke: theme.palette.primary.blue, strokeWidth: 2 }}
                dot={false} // Hide the dots
            />
        </AreaChart>
    </ResponsiveContainer>
    )

}

export default Chart