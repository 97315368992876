import { Button, Grid, InputBase } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CustomSnackbarContent } from './Alerts';
import { requestCountry } from '../../redux/auth/actions';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4)
    },
    inputEmail: {
        background: '#EBF2FF',
        height: '56px',
        width: '100%',
        border: '1px solid #CACCD5',
        borderRadius: '4px',
        padding: '0 16px'
    },
    button: {
        height: '56px',
        width: '100%',
        fontSize: '18px',
        fontWeight: 500,
        padding: '15px 0',
        backgroundColor: '#2EBC31',
        color: '#fff'

    },
    buttonDisabled: {
        backgroundColor: '#2EBC31 !important',
        color: '#eee !important'
    },
}));

const Subscribe = ({ value }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async () => {
        await requestCountry({
            email,
            country: value
        });
        setSubmitted(true);
    }

    const clear = async () => {
        setEmail('');
        setSubmitted(false);
    }

    return (
        <Grid container className={classes.root} spacing={2}>
            {!submitted && <>
                <Grid item xs={12} sm={8}>
                    <InputBase
                        inputProps={{
                            autoComplete: 'off'
                        }}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        id="email"
                        name='email'
                        autoComplete='false'
                        label="Email address"
                        variant="outlined"
                        className={classes.inputEmail}
                        fullWidth />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        onClick={handleSubmit}
                        disabled={!email || !email.includes('@') || !email.includes('.com')}
                        variant="contained"
                        color="primary"
                        classes={{ root: classes.button, disabled: classes.buttonDisabled }}>
                        {`Join waitlist`}
                    </Button>
                </Grid>
            </>
            }
            {submitted && (
                <Grid item xs={12}>
                    <CustomSnackbarContent variant='success' message={`Success!`} onClose={() => clear()} />
                </Grid>
            )}
        </Grid>
    );
};

Subscribe.propTypes = {
    key: PropTypes.string,
    value: PropTypes.string
}

export default Subscribe;
