import React from 'react';

const Ellipse = ({ height = 12, width = 12, color = "#D9D9D9" }) => {
    return (
            <svg width={width} height={height} viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4.5" cy="4" r="4" fill={color}/>
            </svg>
    );
};

export default Ellipse;
