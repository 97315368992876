import React, { useEffect, useRef, useState } from 'react';
import classes from './UserReviewsSection.module.scss';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../remitbee/theme/Theme';
import { useRouter } from 'next/router';
import Box from '../../../../../remitbee/components/box/Box';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Typography from '../../../../../remitbee/components/typography/Typography';
import ArrowLeftCircled from '../../../../../remitbee/icons/ArrowLeftCircled';
import ArrowRightCircled from '../../../../../remitbee/icons/ArrowRightCircled';
import Avatar from '../../../../../../public/design-system/landing/UserReviewsSection/Avatar.svg';
import Star from '../../../../../../public/design-system/landing/UserReviewsSection/Star.svg';
import Quotes from '../../../../../../public/design-system/landing/UserReviewsSection/Quotes.svg';
import Ellipse from '../../../../../remitbee/icons/Ellipse';
import { googleReviews } from '../../../../../redux/system/actions';
import { parseArrFromString } from './../../../../../shared/utility';

interface Props {
  backgroundColor?: string;
  type?: string;
  title?: string;
  text?: string;
  reviews?: any;
}

const UserReviewsSection: React.FC<Props> = ({ backgroundColor, type, text,title, reviews }) => {
  const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const [selectedTab, setSelectedTab] = useState(0);
  const [userReviews, setUserReviews] = useState([]);
  const router = useRouter();
  const scrollRef = useRef(null);
  const reviewsData = reviews ? parseArrFromString(reviews) : null;

  useEffect(() => {
    const loadReviews = async() => {
        const { success, errorMessage, data } = await googleReviews();
        if(errorMessage || !success) return;
        const reviews = data.GoogleReviews
        setUserReviews(reviews)
    }
    loadReviews()
  }, []);

  const setSelectedTabAndScroll = (index) => {
    if (index > userReviews?.length - 1 || index < 0) return;
    setSelectedTab(index);
    if (scrollRef.current) {
      const containerWidth = scrollRef?.current?.clientWidth;
      const tabWidth = scrollRef?.current?.children[index]?.offsetWidth;
      const tabPosition = scrollRef?.current?.children[index]?.offsetLeft;
      const scrollPosition = tabPosition - (containerWidth / 2 - tabWidth / 2);
      scrollRef?.current?.scrollTo({
        left: isNotMobile ? scrollPosition : tabPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (scrollRef?.current) {
      const container = scrollRef?.current;
      const containerWidth = container?.clientWidth;
      const scrollLeft = container?.scrollLeft;

      let currentIndex = 0;
      let currentScroll = 0;
      Array.from(container?.children)?.some((tab: any, index) => {
        currentScroll += tab?.offsetWidth;
        if (currentScroll > scrollLeft + containerWidth / 2) {
          currentIndex = index;
          return true;
        }
        return false;
      });

      if (scrollLeft === 0) {
        currentIndex = 0;
      }

      setSelectedTab(currentIndex);
    }
  };

  React.useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current?.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef?.current) {
        scrollRef?.current?.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  return (
    <section style={{ backgroundColor: backgroundColor || null }}>
      <Box flexDirection={'column'} className={classes['rb-root']}>
        <Box display="flex" flexDirection={'row'} justifyContent="space-between">
          <Box display="flex" flexDirection={'column'} gap={16} className={classes['rb-content']}>
            <Typography
              className={classes['rb-title']}
              variant={'displayLg'}
              weight={'bold'}
              color={theme.palette.primary.navy}
              align={isNotMobile ? "left" : "center"}
            >
              {title ? title : type === 'review' ? 'Google Reviews': `Don’t just take our word for it`}
            </Typography>
            <Typography
              paragraph={true}
              className={classes['rb-text']}
              variant={'textMd'}
              weight={'regular'}
              color={theme.palette.input.body}
              align={isNotMobile ? "left" : "center"}
            >
              {text? text :`We have thousands of happy Remitbee users to back us up`}
            </Typography>
          </Box>
          {isNotMobile && (
            <Box className={classes['rb-arrows']}>
              <Box onClick={() => setSelectedTabAndScroll(selectedTab - 1)}>
                <ArrowLeftCircled active={selectedTab !== 0} />
              </Box>
              <Box onClick={() => setSelectedTabAndScroll(selectedTab + 1)}>
                <ArrowRightCircled active={selectedTab < (reviews? reviews?.length - 1: userReviews?.length - 1)} />
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {userReviews  && reviewsData === null && (
            <Box
              componentRef={scrollRef}
              display="flex"
              gap={24}
              alignItems={'stretch'}
              className={classes['rb-review-cards']}
            >
              {userReviews?.length > 0 && userReviews.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className={`
                        ${classes['rb-review-box']}
                        ${index === selectedTab && classes['rb-tab-review-active']}
                    `}
                  >
                    <Box className={classes['rb-review']}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Box display="flex" justifyContent="flex-start" gap={24}>
                          <Box className={classes['rb-avatar']}>
                            <img
                              src={item?.image_url || Avatar}
                              className={classes['rb-avatar-image']}
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="center"
                            gap={12}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={8}
                            >
                              {item?.rating &&
                                Array.from({ length: item?.rating }).map((_, index) => (
                                  <img key={index} src={Star} alt="star" />
                                ))}
                            </Box>
                            <Typography
                              className={classes['rb-review-name']}
                              variant={'textXl'}
                              weight={'bold'}
                              color={theme.palette.primary.navy}
                              align="left"
                            >
                              {item?.name}
                            </Typography>
                          </Box>
                        </Box>
                        {isNotMobile && (
                          <Box className={classes['rb-quotes']}>
                            <img src={Quotes} alt="quotes" />
                          </Box>
                        )}
                      </Box>
                      <Typography
                        className={classes['rb-review-text']}
                        variant={'textLg'}
                        weight={'regular'}
                        color={theme.palette.input.body}
                        align="left"
                      >
                        {item?.feedback}
                      </Typography>
                      <Typography
                        className={classes['rb-review-link']}
                        variant={'textMd'}
                        weight={'regular'}
                        color={theme.palette.primary.blue}
                        align="left"
                        onClick={() => window.open(item?.author_url, '_blank')}
                      >
                        See it on Google
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
            {reviewsData && (
            <Box
              componentRef={scrollRef}
              display="flex"
              gap={24}
              alignItems={'stretch'}
              className={classes['rb-review-cards']}
            >
              {reviewsData?.length > 0 && reviewsData.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className={`
                        ${classes['rb-review-box']}
                        ${index === selectedTab && classes['rb-tab-review-active']}
                    `}
                  >
                    <Box className={classes['rb-review']}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Box display="flex" justifyContent="flex-start" gap={24}>
                          <Box className={classes['rb-avatar']}>
                            <img
                              src={item?.image_url || Avatar}
                              className={classes['rb-avatar-image']}
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="center"
                            gap={12}
                          >
                           
                            <Typography
                              className={classes['rb-review-name']}
                              variant={'textXl'}
                              weight={'bold'}
                              color={theme.palette.primary.navy}
                              align="left"
                            >
                              {item?.name}
                            </Typography>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={8}
                            >
                             { item?.inst && <><img key={0} src={item?.inst} alt="star" />
                              <Typography>{item?.instFollower}</Typography></>}
                              { item?.tik && <><img key={0} src={item?.tik} alt="star" />
                              <Typography>{item?.tikFollower}</Typography></>}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        className={classes['rb-review-text']}
                        variant={'textLg'}
                        weight={'regular'}
                        color={theme.palette.input.body}
                        align="left"
                      >
                        {item?.feedback}
                      </Typography>
                      <Typography
                        className={classes['rb-review-link']}
                        variant={'textMd'}
                        weight={'regular'}
                        color={theme.palette.primary.blue}
                        align="left"
                      >
                       <a href={item.link}> {item?.linkText} </a>
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" gap={16}>
            {userReviews && userReviews?.length > 0 && 
              Array.from({ length: userReviews?.length }).map((_, index) => (
                <Box
                  className={classes['rb-ellipse']}
                  onClick={() => setSelectedTabAndScroll(index)}
                >
                  <Ellipse color={index === selectedTab ? '#2563EB' : '#C5D9FC'} />
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default UserReviewsSection;
