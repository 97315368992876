import React from 'react'
import CurrencyInputField from './CurrencyInputField'
import classes from './MoneyTransferBox.module.scss'
import StepperCircle from '../../icons/StepperCircle'
import Typography, { Variant as TypographyVariant, Weight as TextWeight } from '../typography/Typography'
import TextArrowRight from '../../icons/TextArrowRight'

const MoneyTransferBox:React.FC<{ 
    /**
     * `string` - unique character
     */
    id: string;
    /**
     * `string` to be passed, strikes the receiving value & and displays text in green.
     */
    specialRateText?: string;
    /**
     * `iso2` - string to be passed.
     */
    sendingFlagCode: string;
    /**
     * `string` to be passed, currency value of the sending country
     */
    sendingCurrency: string;
    /**
     * `boolean`, displays a `Loading...` text instead of the input
     */
    sendAmountLoading?: boolean;
    /**
     * `Function` to be passed, input value will be accessible in params
     */
    onSendValueHandleChange?: Function;
    /**
     * `Function` to be passed, input value will be accessible in params
     */
    onSendCountryChange?: Function;
    /**
     * `string` - recommended to pass state as string.
     */
    sendAmountValue?: string;
    /**
     * `boolean` - to give access to change country
     */
    sendCountrySelectable?: boolean;
    /**
     * `string` - to indicate error message
     */
    sendFieldErrorMessage?: string;
    /**
     * `iso2` - string to be passed.
     */
    receivingFlagCode: string;
    /**
     * `string` to be passed
     */
    receivingCurrency: string;
    /**
     * `string` to be passed, currency value of the receiving country
     */
    receivingCurrencyValue?: string;
    /**
     * `boolean`, displays a `Loading...` text instead of the input
     */
    receivingAmountLoading?: boolean;
    /**
     * `Function` to be passed, input value will be accessible in params
     */
    onReceiveValueHandleChange?: Function;
    /**
     * `string` - recommended to pass state as string.
     */
    receivingAmountValue?: string;
    /**
     * `Function` to be passed, input value will be accessible in params
     */
    onReceivingCountryChange?: Function;
    /**
     * `boolean` - to give access to change country
     */
    receivingCountrySelectable?: boolean;
    /**
     * `string` - to indicate error message
     */
    receiveFieldErrorMessage?: string
    /**
     * `List of countries` - Should contain rate,iso2,currency details
     */
    countriesList: any[];
    /**
     * `Function - Enter the sending field that should be searched [Use this one for Multicurrency]
     */
    sendingCountrySearch?: Function;
    /**
     * `Function - Enter the receiving field that should be searched
     */
    receivingCountrySearch?: Function;
    receivingCountrySuggestionsLabel?: string;
    receivingCountrySuggestionsRequestDialog?: Function;
    autoFocus?: boolean;
    showPopularCurrencies?: boolean;
    showCountryName?: boolean;
}> = ({
    id,
    specialRateText,
    sendingFlagCode,
    sendingCurrency,
    sendAmountLoading,
    onSendValueHandleChange,
    onSendCountryChange,
    sendAmountValue,
    sendCountrySelectable,
    sendFieldErrorMessage,
    receivingFlagCode,
    receivingCurrency,
    receivingCurrencyValue,
    receivingAmountLoading,
    onReceiveValueHandleChange,
    receivingAmountValue,
    onReceivingCountryChange,
    receivingCountrySelectable,
    receiveFieldErrorMessage,
    countriesList,
    sendingCountrySearch,
    receivingCountrySearch,
    receivingCountrySuggestionsLabel,
    receivingCountrySuggestionsRequestDialog,
    autoFocus=false,
    showPopularCurrencies = false,
    showCountryName = false,
}) => {

    return (
        <div id={id} className={classes[`rb-moneyTransfer-container`]}>
            <CurrencyInputField
                label="You send"
                id="sendingEnd"
                countrySelectable={sendCountrySelectable}
                flagCode={sendingFlagCode}
                currency={sendingCurrency}
                loading={sendAmountLoading}
                handleChange={onSendValueHandleChange}
                value={sendAmountValue}
                autoFocus={autoFocus}
                countriesList={countriesList}
                showCountryName={showCountryName}
                showPopularCurrencies={showPopularCurrencies}
                handleCountryChange={onSendCountryChange}
                errorMessage={sendFieldErrorMessage}
                editable={!receivingAmountLoading}
                onSearch={sendingCountrySearch}
                minValue={0}
            />

            <div className={classes[`rb-exchangeRate-info`]}>
                <div className={classes[`rb-dotted-line`]}></div>
                <div className={classes[`rb-rate`]}>
                    <div className={classes[`rb-circle`]}>
                        <StepperCircle status="active" />
                    </div>
                    {receivingCurrencyValue && receivingCurrency && <div className={classes[`rb-rateAmount`]}>
                        <div className={classes[`rb-convertedRate`]}>
                            <Typography id='amount' weight={TextWeight.medium} variant={TypographyVariant.body1} color="#31517A">{` 1 ${sendingCurrency}`}</Typography>
                            <TextArrowRight />
                            <Typography id='amount' weight={TextWeight.medium} variant={TypographyVariant.body1} style={{ textDecoration: specialRateText ? 'line-through' : 'inherit' }} color="#31517A">{` ${receivingCurrencyValue} ${receivingCurrency}`}</Typography>
                            {specialRateText && <Typography id="specialRate" weight={TextWeight.medium} variant={TypographyVariant.body1} color='#008000'>{` ${specialRateText} ${receivingCurrency}`}</Typography>}
                        </div>
                    </div>}
                </div>
            </div>

            <CurrencyInputField
                countrySelectable={receivingCountrySelectable}
                label="They receive"
                id="receivingEnd"
                flagCode={receivingFlagCode}
                currency={receivingCurrency}
                loading={receivingAmountLoading}
                handleChange={onReceiveValueHandleChange}
                value={receivingAmountValue}
                countriesList={countriesList}
                showPopularCurrencies={showPopularCurrencies}
                showCountryName={showCountryName}
                handleCountryChange={onReceivingCountryChange}
                errorMessage={receiveFieldErrorMessage}
                editable={!sendAmountLoading}
                onSearch={receivingCountrySearch}
                setOpenRequestDialog={receivingCountrySuggestionsRequestDialog}
                receiveSuggestionsLabel={receivingCountrySuggestionsLabel}
            />
        </div>
    )
}

export default MoneyTransferBox