/* eslint-disable react/no-array-index-key */
import {
    IconButton, List,
    ListItem, Typography, useMediaQuery
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
    arrayOf, bool, func, oneOf, shape, string
} from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import TextField from '../../TextField';
import CountryListItem from './countryListItem/CountryListItem';
import RequestDialog from '../../RequestDialog';

const useStyles = makeStyles((theme) => ({
  listStyle: {
    width: '100%',
    borderRadius: '6px',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 350,
    [theme.breakpoints.up('xs')]: {
      minWidth: 340
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 270
    }
  },
  inputItem: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(1),
    borderBottom: '1px solid #CACCD5',
    '&:hover': {
      backgroundColor: '#fff !important'
    }
  },
  input: {
    outline: 'none',
    border: 0,
    borderRadius: 0
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  subtitle: {
    fontSize: '18px',
    lineHeight: '26px',
    cursor: 'none'
  },
  notFound: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: 308
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 270
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
    width: '100%',
    color: '#626680',
    fontSize: 16
  }
}));

const CountryList = forwardRef(({
  onItemClick,
  filteredItems,
  setFilteredItems,
  countriesList,
  hasFlagIcon,
  options,
  searchOptions,
  menuStyle,
  currency,
  // open
}, ref) => {
  const classes = useStyles();
  const { t } = useTranslation('rates');
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const [openRequestDialog, setOpenRequestDialog] = useState(false);

  const filterList = (event) => {
    let updatedList = countriesList;
    updatedList = updatedList && updatedList.length && updatedList.filter((item) => {
      let filterOptions = searchOptions?.length > 0 ? searchOptions : options;
      let searchString = "";
      filterOptions?.map(option => {
        if (item[option]) searchString += (" " + item[option]);
      })

      let result = false;
      result = searchString.toLowerCase().includes(event.target.value.toLowerCase())
      return result;
    });
    setFilteredItems(updatedList);
  };
  const countries = {
    favorites: [],
    all: [],
  };
  filteredItems.forEach((element, index) => {
    const isFavorite = element.favorite === '1' || element.favorite === true;
    const countryListItem = (
      <CountryListItem
        item={element}
        onItemClick={onItemClick}
        key={`${isFavorite ? 'popular' : 'common'}-countries-content-${index}`}
        options={options}
        hasFlagIcon={hasFlagIcon}
      />
    );
    if (isFavorite) countries.favorites.push(countryListItem);
    else countries.all.push(countryListItem);
  });

  return (
    <div ref={ref}>
      <List className={classes.listStyle} style={menuStyle && !isMobile ? menuStyle : null}>
        <ListItem key="search-input" classes={{ root: classes.inputItem }} onClick={(e) => e.stopPropagation()}>
          <TextField
            onChange={filterList}
            autoFocus
            classes={{ notchedOutline: classes.input }}
            placeholder={currency ? 'Enter a currency' : 'Enter a country or currency'}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <IconButton size='small'>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
        {countries && countries.favorites && countries.favorites.length > 0 && (
          <>
            <ListItem key="header1">
              <Typography variant="h4" className={classes.subtitle}>{t('rates:popular_countries')}</Typography>
            </ListItem>
            {countries && countries.favorites && countries.favorites}
            {countries && countries.all && countries.all.length > 0 && <br />}
          </>
        )}
        {countries && countries.all && countries.all.length > 0 && <ListItem key="header2">
          <Typography variant="h4" className={classes.subtitle}>{t(currency ? 'rates:all_currencies' : 'rates:all_countries')}</Typography>
        </ListItem>}
        {countries && countries.all}

        {countries && countries.favorites && countries.favorites.length === 0 && countries.all.length === 0 && (
          <ListItem key="notfound" className={classes.listStyle}>
            <Typography variant="h4" className={classes.notFound}>
              {currency ? `Can't find your currency? ` : `Can't find your country? `}
              <a id="request_country" className={classes.link} onClick={() => setOpenRequestDialog(true)}>
                {currency ? `Request a new currency` : `Request a new country`}
              </a>
              {` from Remitbee team, and we'll notify you when it's available.`}
            </Typography>
          </ListItem>
        )}
      </List>
      <RequestDialog request_type={currency ? 'currency' : 'country'} open={openRequestDialog} handleClose={setOpenRequestDialog} />
    </div>
  );
});

CountryList.propTypes = {
  onItemClick: func.isRequired,
  filteredItems: arrayOf(shape({
    currency_code: string,
    currency_name: string,
    country_to: string,
  })),
  countriesList: arrayOf(shape({
    currency_code: string,
    currency_name: string,
    country_to: string,
  })),
  setFilteredItems: func.isRequired,
  hasFlagIcon: bool,
  options: arrayOf(
    oneOf(['currency_code', 'currency_name', 'country_to', 'country_name', 'country_from']),
  ),
  searchOptions: arrayOf(
    oneOf(['currency_code', 'currency_name', 'country_to', 'country_name', 'country_from']),
  ),
  menuStyle: shape({})
};

CountryList.defaultProps = {
  filteredItems: [],
  countriesList: [],
  hasFlagIcon: true,
  options: ['currency_code', 'currency_name', 'country_to', 'country_name', 'country_from'],
  searchOptions: [],
  menuStyle: {},
};

export default CountryList;
