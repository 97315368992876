import {
    Dialog,
    DialogActions,
    DialogContent
} from '@mui/material';
import {
    arrayOf, bool,
    func, node, oneOf, oneOfType, shape, string
} from 'prop-types';
import React from 'react';
import DialogTitle from './dialogTitle/DialogTitle';
import { useMediaQuery } from '@mui/material';
import CommonHeader from '../../common/CommonHeader';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  layoutPadding:{
    padding:'24px 24px 12px'
  }
}))

const DialogLayout = ({
  dialogTitleContent,
  dialogActionContent,
  dialogStyles,
  dialogTitleStyles,
  dialogContentStyles,
  open,
  onCloseHandler,
  children,
  // titleAlignSelf,
  // maxWidth,
  showArrowBack,
  arrowBackOnClickHandler,
  showHeaderOnMobile,
  fullScreenOnMobile,
  ...props
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  let contentStyle = {};
  if(props.scroll == 'body') contentStyle = { style: { overflowY: "hidden" } };
  if(!onCloseHandler) return;

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      classes={{paper:classes.layoutPadding}}
      style={dialogStyles}
      fullWidth
      fullScreen={fullScreenOnMobile}
      {...props}
    >
      {isMobile && showHeaderOnMobile && (
        <CommonHeader
          activeStep={0}
          onClose={onCloseHandler}
          hideProgress
          customBack={onCloseHandler} />
      )}
      {/* <FullColumn style={fullColumnStyle}>
        <Box display="flex" flexDirection="column" alignItems="stretch"> */}
          <DialogTitle
            customstyles={dialogTitleStyles}
            onClose={onCloseHandler}
            showArrowBack={showArrowBack}
            arrowBackOnClickHandler={arrowBackOnClickHandler}
          >
            {dialogTitleContent}
          </DialogTitle>
          <DialogContent {...contentStyle} style={{ padding: '8px', ...dialogContentStyles }}>
            {children}
          </DialogContent>
          {dialogActionContent && <DialogActions>
            {dialogActionContent}
          </DialogActions>}
        {/* </Box>
      </FullColumn> */}
    </Dialog>
  );
};

DialogLayout.propTypes = {
  open: bool,
  onCloseHandler: func.isRequired,
  dialogTitleContent: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
  dialogActionContent: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
  children: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
  dialogStyles: shape(),
  // titleAlignSelf: string,
  // maxWidth: string,
  showArrowBack: bool,
  arrowBackOnClickHandler: func,
  scroll: oneOf(['body', 'paper']),
};

DialogLayout.defaultProps = {
  open: false,
  dialogTitleContent: '',
  dialogActionContent: '',
  children: null,
  dialogStyles: {},
  // titleAlignSelf: 'center',
  // maxWidth: 'xs',
  showArrowBack: false,
  arrowBackOnClickHandler: func,
  scroll: 'body',
};

export default DialogLayout;
