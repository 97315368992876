import React from 'react';

const Loader = ({ width = 80, height = 81 }) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 80 81" fill="none">
      <path d="M45.0068 5.85996C51.9269 6.86017 58.3888 9.91011 63.5595 14.6166C68.7303 19.3232 72.3729 25.4705 74.0177 32.2663C75.6626 39.0621 75.2343 46.1948 72.7881 52.7449C70.342 59.295 65.99 64.9624 60.2932 69.0164C54.5965 73.0704 47.816 75.3252 40.826 75.4902C33.8359 75.6553 26.9567 73.7229 21.0749 69.9422C15.1932 66.1615 10.5787 60.7058 7.82616 54.2784C5.07363 47.851 4.30928 40.7464 5.63165 33.8806" stroke="url(#paint0_linear)" strokeWidth="10" strokeLinecap="round">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 40 40.5"
          to="360 40 40.5"
          dur="2s"
          repeatCount="indefinite" />
      </path>
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="0" x2="80" y2="81" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D9D9D9" stopOpacity="0.6" />
          <stop offset="0.996389" stopColor="#1160F7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Loader;
