import { Box, Button, Grid, Fade, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: theme.spacing(1)
    },
    box: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            borderRight: '1px solid #CACCD5',
            borderBottom: 0,
            justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            borderBottom: '1px solid #CACCD5',
            borderRight: 0,
            justifyContent: 'left',
        }
    },
    rightBox: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        width: '100%',
        padding: theme.spacing(3),
        background: '#fff',
        border: '1px solid #CACCD5',
        borderRadius: 6,
        marginTop: theme.spacing(4)
    },
    name: {
        fontWeight: 700,
        color: '#31517A',
        [theme.breakpoints.up('sm')]: {
            fontSize: '36px',
            lineHeight: '52px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '26px',
            lineHeight: '38px',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        fontWeight: 500,
        color: '#31517A',
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            fontSize: '24px',
            lineHeight: '35px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '26.5px',
        }
    },
    description: {
        fontSize: '16px',
        lineHeight: '23.5px',
        color: '#626680',
        marginTop: theme.spacing(2)
    },
    text: {
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(0, 4),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(3, 0),
        }
    },
    image: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            maxHeight: '160px'
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: '120px'
        }
    },
    copyButton: {
        height: '56px',
        background: '#fff',
        border: '1px dashed #1160F7',
        color: '#1160F7',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '29px',
        '&:hover': {
            background: '#E9F5FF'
        }
    },
    actionButton: {
        height: '56px',
        background: '#fff',
        border: '1px solid #1160F7',
        color: '#1160F7',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '26px',
        '&:hover': {
            background: '#1160F7',
            color: '#fff'
        }
    },
    tooltip: {
        padding: theme.spacing(1, 2),
        background: '#6D6D6D',
        color: '#fff',
        fontSize: '14px',
        lineHeight: '14px',
        boxShadow: '0px 0px 8px #00000040',
        maxWidth: '200px',
        margin: theme.spacing(1, 0)
        },
    tooltipArrow: {
        color: '#6D6D6D'
    },
}));

const PromoCodes = ({ list }) => {
    const classes = useStyles();
    const [openTooltip, setOpenTooltip] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    if (list.length > 0) {
        list = list.sort((a, b) => {
            return new Date(a.expiresAt) - new Date(b.expiresAt);
        });
    }

    const handleCopy = (event, code, index) => {
        event.preventDefault();
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(code);
            setOpenTooltip(index);
            
            setTimeout(() => {
                setOpenTooltip(null);
            }, 600);
        }
    }

    const handleRedirect = (event, link) => {
        event.preventDefault();
        if (window) window.open(link, '_blank').focus();
    }

    if (list && list?.length > 0) return (
        <Grid container className={classes.grid}>
            {list.map((item, index) => {
                const { name, code, title, description, expiresAt, actionText, actionLink } = item;
                const validDate = expiresAt => {
                    return (new Date(expiresAt) !== "Invalid Date") && !isNaN(new Date(expiresAt));
                };

                return (
                    <Grid item container className={classes.root}>
                        <Grid item xs={12} sm={3}>
                            {name && 
                                <Box className={classes.box}>
                                    <Typography className={classes.name}>{name}</Typography>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.text}>
                                <Typography className={classes.title}>{title}</Typography>
                                <Typography className={classes.description} align='left'>{description}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {code &&
                                <Box className={classes.rightBox}>
                                    <Box style={{ width: '100%' }}>
                                    <Tooltip
                                        arrow
                                        onClose={() => setOpenTooltip(null)}
                                        open={openTooltip === index}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        TransitionComponent={Fade}
                                        title={`Copied to clipboard`}
                                        placement='bottom'
                                        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}>
                                        <Button
                                            onClick={event => handleCopy(event, code, index)}
                                            className={classes.copyButton}
                                            fullWidth>
                                            {code}
                                            <img src='/new/copy.svg' className={classes.image} alt="Copy to clipboard" />
                                        </Button>
                                    </Tooltip>
                                    {expiresAt && validDate && 
                                        <Typography className={classes.description} align={isMobile ? 'left' : 'right'}>{`Ends ${expiresAt}`}</Typography>
                                    }
                                    </Box>
                                </Box>
                            }
                            {actionText &&
                                <Box className={classes.rightBox}>
                                    <Button
                                        onClick={event => handleRedirect(event, actionLink)}
                                        className={classes.actionButton}
                                        fullWidth>
                                        {actionText}
                                    </Button>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    );
    else return null;
};

PromoCodes.propTypes = {
    list: PropTypes.array
}

export default PromoCodes;
