import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { bool } from 'prop-types';
import React from 'react';
import { container } from '../globalStyles';

const useStyles = backgroundColor => makeStyles(theme => ({
  container,
  root: {
    display: 'flex',
    background: backgroundColor || '#fff',
    textAlign: 'left',
    padding: '80px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 0',
    },
  },
  center: {
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'column',
  },
  centerLeft: {
    maxWidth: '750px',
    marginLeft: "190px",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px !important"
    },
  }
}))

const Section = ({
  children,
  center,
  centerLeft,
  backgroundColor,
  backgroundImage,
  backgroundLinear,
  backgroundWithStyles,
}) => {
  const classes = useStyles(backgroundColor)();
  const rootStyle = {};

  if (backgroundImage) {
    rootStyle.background = `url(${backgroundImage}) no-repeat center`;
    rootStyle.backgroundSize = 'cover';
  }

  if(backgroundWithStyles) {
    rootStyle.background = `${backgroundWithStyles} no-repeat center`;
    rootStyle.backgroundSize = 'cover';
  }

  if(backgroundLinear) {
    rootStyle.background = backgroundLinear;
    rootStyle.backgroundSize = 'cover';
    rootStyle.backgroundRepeat  = 'no-repeat';
    rootStyle.backgroundPosition = 'bottom'
  }

  return (
    <div className={classes.root} style={rootStyle}>
      <Grid container className={classnames(classes.container, center && classes.center, centerLeft && classes.centerLeft)}>
        {children}
      </Grid>
    </div>
  )
}

Section.propTypes = {
  center: bool
};
Section.defaultProps = {};

export default Section;