import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "24px",
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    marginBottom: '8px'
  },
  root: {
    width: '337px',
    background: '#F4F4F4',
    border: '1px solid #CACCD5',
    borderRadius: '16px',
    boxShadow: 'none'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 16px',
    gap: '24px',
  },
  logo: {
    //width: '100%',
    maxHeight: '60px',
  },
  description: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    color: '#626680'
  },
  seeOfferButton: {
    color: 'blue',
    textDecoration: 'none',
  },
});


function PartnerCard({ partner }) {
  const classes = useStyles();

  return (
    (<Card className={classes.root}>
      <CardContent className={classes.contentContainer}>
        <img
          src={partner.cardLogo}
          alt="Logo"
          className={classes.logo}
        />
        <Typography
          variant="body1"
          className={classes.description}
        >
          {partner.cardDescription}
        </Typography>
        <Button
          component={Link}
          href={partner.cardActionUrl}
          variant="text"
          className={classes.seeOfferButton}
        >
          {partner.cardActionText}
        </Button>
      </CardContent>

    </Card>)
  );
}

export default PartnerCard;
