import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: 'calc(25% - 10px)',
        maxWidth: '250px',
        gap: '16px',
        [theme?.breakpoints?.down('sm')]: {
            width: 'calc(50% - 10px)',
        }
    },
    imageWrapper: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width: '100%',
        height: '150px',
        border: '1px solid #CACCD5',
        borderRadius: '8px',
    },
    label: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#626680',
        width: '100%',
        textAlign: 'center'
    },
    image: {
        width: '180px',
        height:'120px',
        [theme?.breakpoints?.down('sm')]: {
            width: '120px',
            height: '80px'
        },
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    }
}))

const OperatorCardPublic = ({label, image, altImage}) => {
    const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.imageWrapper}>
        <div className={classes.image}>
         <img src={image} alt={label} onError={(e) => e.target.src = altImage} />
        </div>
      </div>
      <p className={classes.label}>{label}</p>
    </div>
  )
}

export default OperatorCardPublic