import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Box, Typography } from '@mui/material';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
    maxWidth: '770px'
  },
  top: {
    background: '#fff',
    border: '1px solid #CACCD5',
    justifyContent: 'space-between',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    padding: theme.spacing(3, 6),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3),
    }
  },
  list: {
    background: '#FBFCFF',
    borderTop: '1px solid #CACCD5',
    borderLeft: '1px solid #CACCD5',
    borderRight: '1px solid #CACCD5',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3),
    },
    '&:last-child': {
      borderBottom: '1px solid #CACCD5',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    },
  },
  listEvenRow: {
    background: '#fff',
    borderTop: '1px solid #CACCD5',
    borderLeft: '1px solid #CACCD5',
    borderRight: '1px solid #CACCD5',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3),
    },
    '&:last-child': {
      borderBottom: '1px solid #CACCD5',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    },
  },
  remitbee: {
    background: '#1160F7',
    borderRadius: '5px',
    margin: '0 -8px !important',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 7),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4),
    }
  },
  title: {
    fontFamily: 'Inter',
    color: '#31517A',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
  },
  boxTitle: {
    width: '33.33%',
  },
  content: {
    padding: 0,
    margin: 0,
    width: '33.33%',
    maxHeight: '60px',
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    maxWidth: '180px',
    width: '100%',
    height: '100%'
  },
  imageBank: {
    float: 'left',
    maxWidth: '134px',
    width: '100%',
    height: '100%'
  },
  remitbeeValue: {
    fontFamily: 'Inter',
    color: '#fff',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    }
  },
  value: {
    fontFamily: 'Inter',
    color: '#626680',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    }
  },
  captionBox: {
    padding: theme.spacing(1),
    width: '100%',
    maxWidth: '770px'
  },
  caption: {
    fontFamily: 'Inter',
    color: '#626680',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
});

const ComparisonTable = ({date, columns, secondaryColumn, data : tempData, classes, tableData}) => {

  const data = tempData ? tempData : tableData ? tableData : null;
  const columnsNames = columns && columns.split(',');

  return (
    <>
      <div className={classes.root}>
        {columnsNames && <Box display='flex' className={classes.top}>
          <Box className={classes.boxTitle}>
            <Typography component='div' className={classes.title} align='left'>{columnsNames[0]}</Typography>
          </Box>
          {secondaryColumn && <Box className={classes.boxTitle}>
            <Typography component='div' className={classes.title} align='right'>{secondaryColumn}</Typography>
          </Box>}
          <Box className={classes.boxTitle}>
            <Typography component='div' className={classes.title} align='right'>{columnsNames[1]}</Typography>
          </Box>
        </Box>}

        {data && data.map((item, index) => (
          <Box display='flex' className={item.provider === 'remitbee' ? classes.remitbee : index % 2 ? classes.listEvenRow : classes.list} key={index}>
            {item.image && <Box className={classes.content}>
              {item.provider === 'remitbee' ?
                <img width='100%' height='100%' src={`/remitbee_logo_white.png`} alt='Remitbee' className={classes.image} /> :
                <img className={classes.imageBank} src={item.image} alt={item.provider} />}
            </Box>}
            {item.name && <Box className={classes.content}>
              {item.provider === 'remitbee' ?
                <img width='100%' height='100%' src={`/remitbee_logo_white.png`} alt='Remitbee' className={classes.image} /> :
                <Typography component='div' align='left' className={classes.value} style={{ maxWidth: 310 }}>{item.name}</Typography>
              }
              </Box>
            }
            {item.secondaryValue && <Box className={classes.content}>
                <Typography component='div' align='center' className={item.provider === 'remitbee' ? classes.remitbeeValue : classes.value}>{item.secondaryValue}</Typography>
              </Box>
            }
            <Box className={classes.content}>
              <Typography  component='div'align='right' className={item.provider === 'remitbee' ? classes.remitbeeValue : classes.value}>{item.value}</Typography>
            </Box>
          </Box>
        ))}

      </div>
      {date && <Box className={classes.captionBox}>
        <Typography className={classes.caption}>{`*Per data collected in ${date}`}</Typography>
      </Box>}
    </>
  )
}

export default withStyles(styles)(ComparisonTable);
