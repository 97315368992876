import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import { Dialog, DialogActions, DialogContent} from '@mui/material';
import dynamic from 'next/dynamic';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';

const CustomButton = dynamic(() => import('../landingPage/components/CustomButtonsWebsite/CustomButton'));

const useStyles = makeStyles(theme => ({
    iframe: {
        width: '100%',
        height: '100%',
        borderRadius: '20px',
        borderStyle: 'solid',
        borderWidth: '0px',
      },
    iframeDiv: {
        width: '550px',
        height: '300px',
        top: '50%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            transform: 'translateY(70%)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    video: {
        width: '100%',
        borderRadius: '20px',
      },
    videoDiv: {
        minWidth: '100%',
        top: '50%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            transform: 'translateY(70%)',
        },
    },
    btnGrid:{
        [theme.breakpoints.up('md')]: {
            minWidth: '400px',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '340px',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
        }
    },
    btnGridPop:{
        [theme.breakpoints.down('sm')]: {
            minWidth: '350px',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
        }
    },
    icon:{
        marginRight: theme.spacing(1),
        marginBottom: -theme.spacing(1),
    },
    button: {
        height: '56px',
        fontSize: '18px',
        lineHeight: '34px',
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            width: '286px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    actionButton: {
        height: '56px',
        fontSize: '18px',
        lineHeight: '34px',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(4),
        width: '400px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: "0px",
        }
    },
    popButton: {
        height: '56px',
        fontSize: '18px',
        lineHeight: '34px',
        width: '80%',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
}));

export default function VideoPopUp(props) {
    const {video, videoPop} = props;
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
        <Box display={isMobile ? 'block' : 'flex'} spacing={2}  className='classes.btnGrid'>
        {!videoPop && <Box>
             <CustomButton href="/signup" className={classes.button} type="blue">
                Start Sending Today
            </CustomButton>
        </Box>}
        <Box>
            <CustomButton variant="outlined" onClick={handleClickOpen} action className={!videoPop ? classes.actionButton : classes.popButton} type={'outlinedBlue'}>
                <Typography className={!videoPop ? classes.btnGrid : classes.btnGridPop }>
                    <PlayIcon className={classes.icon}/>
                    {isMobile? ' How you can save more' : 'Check how you can save more money'}
                </Typography>
                </CustomButton>
        </Box>
        </Box>
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
             >
            <DialogActions>
                <IconButton size='small' className={classes.closeIcon} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent>
            <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
              >
                <Box >
                {video.includes('youtube.com/embed/') ? 
                <div className={classes.iframeDiv}>
                    <iframe src={video} className={classes.iframe} allowFullScreen  />
                </div>
                :
                <div className={classes.videoDiv}>
                    <video src={video} preload="auto" controlsList='nodownload' className={classes.video} controls  />
                </div>
                }
                </Box>
                </Box>
            </DialogContent>
            
            </Dialog>
        </div>
  );
}