import React from 'react'

const TopUp = ({ height = 20, width = 20, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M9.58333 17.5H5.41667C4.97464 17.5 4.55072 17.3244 4.23816 17.0118C3.92559 16.6993 3.75 16.2754 3.75 15.8333V4.16667C3.75 3.72464 3.92559 3.30072 4.23816 2.98816C4.55072 2.67559 4.97464 2.5 5.41667 2.5H12.0833C12.5254 2.5 12.9493 2.67559 13.2618 2.98816C13.5744 3.30072 13.75 3.72464 13.75 4.16667V8.33333M7.91667 3.33333H9.58333M8.75 14.1667V14.1748M16.25 12.5H14.1667C13.8351 12.5 13.5172 12.6317 13.2828 12.8661C13.0484 13.1005 12.9167 13.4185 12.9167 13.75C12.9167 14.0815 13.0484 14.3995 13.2828 14.6339C13.5172 14.8683 13.8351 15 14.1667 15H15C15.3315 15 15.6495 15.1317 15.8839 15.3661C16.1183 15.6005 16.25 15.9185 16.25 16.25C16.25 16.5815 16.1183 16.8995 15.8839 17.1339C15.6495 17.3683 15.3315 17.5 15 17.5H12.9167M14.5833 17.5V18.3333M14.5833 11.6667V12.5" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default TopUp