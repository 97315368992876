import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dynamic from 'next/dynamic';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';
import Hero from '../components/Hero/Hero';

const FeeSlider = dynamic(() => import('../../../components/landingPage/components/FeeSlider/FeeSlider'))
const ArticleSearchInput = dynamic(() => import('../components/AutocompleteSearch/ArticleSearchInput'))
const CustomButton = dynamic(() => import('../components/CustomButtonsWebsite/CustomButton'))

const styles = theme => ({
    titleContainer: {
        textAlign: props => props.itemsCentered ? 'center' : 'left',
        width: '100%',
        "@media (max-width: 959px)": {
                textAlign: "left !important",
        },
    },
    title: {
        color: 'white',
        fontSize: '42px',
        fontWeight: '500',
        "@media (max-width: 959px)": {
            fontSize: "30px !important",
            textAlign: 'left',
        },
    },
    bigTitle: {
        color: 'white',
        fontSize: '60px',
        fontWeight: '700',
        "@media (max-width: 959px)": {
            fontSize: "40px !important",
            textAlign: 'left',
        },
    },
    subTitle: {
        color: 'white',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.5',
        opacity: '0.9',
        "@media (max-width: 959px)": {
            fontSize: '20px !important',
        },
    },
    bigSubTitle: {
        color: 'white',
        fontSize: '45px',
        fontWeight: '300',
        lineHeight: '1.5',
        opacity: '0.9',
        "@media (max-width: 959px)": {
            fontSize: '30px !important',
        },
    },
    heroImage: {
        // textAlign: 'center',
        paddingTop: '60px !important',
        zIndex: '10',
    },
    heroContainer: {
        padding: '170px 0 120px 0',
        background: props => `url('${props.backgroundImage}') center no-repeat `, 
        backgroundSize: 'cover !important',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '100px',
            paddingBottom: '60px',
        },
    },
    heroContainerSlanted: {
        padding: '170px 0 120px 0',
        background: props => `url('${props.backgroundImage}') center no-repeat `, 
        backgroundSize: 'cover !important',
        clipPath: 'polygon(0 0, 100% 0, 100% 90%, 0 100%)'
    },
    heroButton: { 
        marginTop: '50px',
    }, 
    container: {
        backgroundColor: "white"
    }
})
const useStyles = makeStyles(styles);


function Component({
    title,
    text,
    feeSliderContent,
    helpSection,
    itemsCentered,
    slanted,
    buttonText,
    backgroundImage,
    buttonUrl,
    buttonType,
    largeFont,
    section
}) {
    const classes = useStyles({backgroundImage, itemsCentered});
    const feeSiderItems = feeSliderContent && parseArrFromString(feeSliderContent);
    return (
        <div className={classes.container}>
            <Hero
                className={slanted ? classes.heroContainerSlanted : classes.heroContainer}
                backgroundImage={backgroundImage}
            >
                <Grid container direction="column" justifyContent={itemsCentered ? 'center' : 'left'} alignItems={itemsCentered ? 'center' : 'left'} spacing={2}>
                    <Grid className={classes.titleContainer} item xs={12}>
                        <Typography variant="h1" className={largeFont ? classes.bigTitle : classes.title}>{title}</Typography>
                    </Grid>
                    {helpSection && <Grid item xs={8} container>
                        <ArticleSearchInput />
                    </Grid>}
                    <Grid item xs={12}>
                        <Typography variant="body1" className={largeFont ? classes.bigSubTitle : classes.subTitle}>{text}</Typography>
                    </Grid>
                    {buttonText && <Grid item xs={12} className={classes.heroButton}>
                        <CustomButton type={buttonType} href={buttonUrl} target='_blank'>{buttonText}</CustomButton>
                    </Grid>}
                    {feeSiderItems && <Grid item xs={12} className={classes.heroImage}>
                        <FeeSlider content={feeSiderItems[0]} />
                    </Grid>}
                    {section}
                </Grid>
            </Hero>
        </div>

    )
}

Component.defaultProps = {
    backgroundImage: '/img/landing/terms_condiotions_bg.png',
    buttonType: 'green'
}

export default Component;
