import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import ErrorSplash from '../src/components/error/ErrorSplash';
import NavigationBarSection from '../src/remitbee/sections/NavigationBarSection/NavigationBarSection';
import FooterSection from '../src/remitbee/sections/FooterSection/FooterSection';

function Error({ statusCode, isMobileServer }) {
  return (
    <>
      <NavigationBarSection isMobileServer={isMobileServer} />
      <ErrorSplash statusCode={statusCode} />
      <FooterSection isMobileServer={isMobileServer} />
    </>
  );
}

Error.getInitialProps = async ({ res, err, req, locale }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  const userAgent = req ? req.headers['user-agent'] : '';
  const isMobileServer = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);

  return {
    statusCode,
    isMobileServer,
    ...(await serverSideTranslations(locale, ['error', 'landing'])),
  };
};

export default Error;