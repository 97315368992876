import React, { useState } from 'react'
import Box from '../../../remitbee/components/box/Box'
import classes from './ProductSelection.module.scss'
import Typography from '../../../remitbee/components/typography/Typography'
import theme from '../../../remitbee/theme/Theme'
import ExchangeIcon from '../../../remitbee/icons/ExchangeIcon'
import Clock from '../../../remitbee/icons/Clock'
import Network from '../../../remitbee/icons/Network'
import CoverageDialog from './CoverageDialog'

export const extractESIMDetails = (data) => {
  if(!data) return;
  
  const regex = /(\d+\s?(?:GB|MB|KB))\s(\d+\s?days?|day|month|months|year|years)/;
  const match = data.match(regex);

  return match ? {
      data: match[1],
      days: match[2]
  } : {
      data: '',
      days: ''
  }
}

const ProductCards = ({ onSelect, plan, selectedPlan, t, destination_type }) => {

    const [showDialog, setShowDialog] = useState(false);

    const planDetails = extractESIMDetails(plan?.description)

    const extraInfo = (icon,key,value) => (
        <Box className={classes['rb-card-detail-each']}>
         <div className={classes['rb-card-field']}>
           {icon}
           <Typography variant='label2' color={theme.palette.primary.navy}>{key}</Typography>
         </div>
         <div className={classes['rb-card-coverage-field']}><Typography align='right' variant='label1' weight='medium' color={theme.palette.primary.navy}>{value}</Typography></div>
       </Box>
    );

    const handleCoverageClick = (e) => {
        e.stopPropagation();
        setShowDialog(true);
    }

  return (
    <>
    <Box onClick={() => onSelect && onSelect(plan)} key={plan.id} className={destination_type !== 'public' ?`${classes['rb-card-container']} ${selectedPlan?.id === plan.id ? classes['rb-card-selected-container'] : ''}` : classes['rb-card-container-row']}>
     <Box className={classes.cardContent}>
     <Box className={`${classes['rb-card-top-section']} ${selectedPlan?.id === plan.id ? classes['rb-card-top-section-selected'] : ''}`}>
      <Box display='flex' alignItems='flex-end' style={{ gap: '5px'}}>
        <Typography variant="h3" weight='semibold' color={theme.palette.primary.navy}>{`$${plan.retailAmount} `}</Typography>
        <Typography variant='body1' color={theme.palette.primary.navy}>{plan.retailAmountUnit}</Typography>
      </Box>
     {destination_type !=='public' && <input
          checked={selectedPlan?.id === plan.id}
          type='radio'
        />}
     </Box>
     <Box className={classes['rb-card-bottom-section']}>
        {extraInfo(
          <ExchangeIcon color={theme.palette.primary.navy} />,
          'Data plan',
          planDetails?.data
        )}
        {extraInfo(
          <Clock width={20} height={20} color={theme.palette.primary.navy} />,
          'Duration',
          planDetails?.days
        )}
        {extraInfo(
          <Network width={20} height={20} color={theme.palette.primary.navy} />,
          (destination_type !== 'local' && (destination_type === 'public' ? plan?.operator?.regions :selectedPlan?.operator?.regions)) ? 'Coverage' : 'Network',
          (destination_type !== 'local' && (destination_type === 'public' ? plan?.operator?.regions :selectedPlan?.operator?.regions)) ? <div id='coverage-country' onClick={(e) => handleCoverageClick(e)} className={classes['rb-additional-countries']} >{`${destination_type === 'public' ? plan?.operator?.regions?.length :selectedPlan.operator?.regions?.length} Countries`}</div> : plan?.operator?.label
        )}
     </Box>
     </Box>
    </Box>

    {showDialog && <CoverageDialog t={t} openDialog={showDialog} setOpenDialog={setShowDialog} countries={destination_type === 'public' ? plan?.operator?.regions :selectedPlan?.operator?.regions} />}
    </>
  )
}

export default ProductCards