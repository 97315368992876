import React, { useRef, useState } from 'react'
import DropDownItem from '../../../../../remitbee/components/dropDown/DropDownItem';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import CheckIcon from '../../../../../remitbee/icons/CheckIcon';
import theme from '../../../../../remitbee/theme/Theme';
import MenuDropdown from '../../../../../remitbee/components/menu/Menu';
import ArrowDown from '../../../../../remitbee/icons/ArrowDown';
import Button from '../../../../../remitbee/components/button/Button';
import classes from './ConverterGraph.module.scss'

const CustomDropDown = ({ options, value, setValue, fullWidth }) => {
    const [openDropDown, setOpenDropDown] = useState(false);
    const parentRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleDropDown = () => setOpenDropDown(!openDropDown);

    const onChange = (option) => {
        setValue(option.value)
        setOpenDropDown(false)
    }

    const listOptions = options.map((option, index) => {
        return (
        <DropDownItem
            key={index}
            onClick={() => onChange(option)}
            data={option}
            value={option.value}
            listStyle={{ padding: '12px 16px', height: '48px', width : buttonRef?.current?.clientWidth  }}
        >
            <Box display='flex' alignItems='center'>
                <Box style={{ width: 'fit-content' }} flexGrow={1}><Typography variant="label" color={theme.palette.primary.navy} >{option.label}</Typography></Box>
                {option.value === value && <Box style={{ width: 'fit-content' }}><CheckIcon width={'20'} height={'20'} /></Box>}
            </Box>
        </DropDownItem>
        )
    })

  return (
    <div ref={parentRef} className={classes['rb-custom-dropdown']}>
    <Button fullWidth={true} parentRef={buttonRef} variant='outlined' id='change-week' onClick={handleDropDown} >
        <Typography variant="label1" color={theme.palette.primary.blue} weight='medium'>{options?.find(o => o.value === value)?.label}</Typography>
        <ArrowDown width={24} height={24} color={'#1160F7'} />    
    </Button>

    <MenuDropdown
        id="language-dropdown"
        parentRef={parentRef}
        open={openDropDown}
        onClose={() => setOpenDropDown(false)}
        setOpen={setOpenDropDown}
        options={listOptions}
        position={{ top: '48px', right: "0px" }}
    />
</div>
  )
}

export default CustomDropDown