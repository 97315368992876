import { useState, useEffect } from 'react';
import NextImage from 'next/image';

interface Props {
    url: string;
    alt?: string | undefined;
    width?: number;
    height?: number;
    className?: any;
    onLoad?: any;
}

const Image: React.FC<Props> = ({ url, alt, width, height, className, onLoad }) => {
    const [loaded, setLoaded] = useState(false);
    const [aspectRatio, setAspectRatio] = useState<number | null>(width && height ? height / width : null);

    useEffect(() => {
        if (width && height) {
            setAspectRatio(height / width);
        }
    }, [width, height]);

    const handleLoadingComplete = (naturalWidth: number, naturalHeight: number) => {
        setLoaded(true);
        if (!width || !height) {
            const calculatedAspectRatio = naturalHeight / naturalWidth;
            setAspectRatio(calculatedAspectRatio);
        }
    };

    return (
        <div
            style={{
                width: '100%',
                maxWidth: '100%',
                aspectRatio: `1 / ${aspectRatio}`,
                position: 'relative'
            }}
        >
            <NextImage
                src={url}
                alt={alt || 'Image alt'}
                style={loaded ? {} : { display: 'none' }}
                width={width}
                height={height}
                layout="responsive"
                loader={({ src, width, quality }) => `${src}?w=${width}&q=${quality}`}
                onLoad={(e) =>
                    handleLoadingComplete((e.target as HTMLImageElement).naturalWidth, (e.target as HTMLImageElement).naturalHeight)
                }
                className={className}
                priority={true}
                placeholder="blur"
                blurDataURL={url}
            />
        </div>
    );
};

export default Image;
