import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../settings/apollo/api/main/mutation';
import { changeCustomerPasswordMutation, getRecentActivityQuery, 
    updateCustomerTwoFactorAuthPreferenceMutation, getCustomerTwoFactorAuthPreferenceQuery,
    updateUserNotificationPreferencesMutation, getUserNotificationPreferencesQuery, verifyCurrentPassword4, validateBankReferenceNumberV2Query } from './graphql.graphql'
import { apiSessionQuery } from '../../settings/apollo/api/session/query';

export const getRecentActivity = async () => await apiMainQuery(getRecentActivityQuery);
export const changeCustomerPassword = async (params) => await apiMainMutation(changeCustomerPasswordMutation, params);

export const getCustomerTwoFactorAuthPreference = async () => await apiMainQuery(getCustomerTwoFactorAuthPreferenceQuery);
export const updateCustomerTwoFactorAuthPreference = async (params) => await apiMainMutation(updateCustomerTwoFactorAuthPreferenceMutation, params);

export const getUserNotificationPreferences = async () => await apiMainQuery(getUserNotificationPreferencesQuery);
export const updateUserNotificationPreferences = async (params) => await apiMainMutation(updateUserNotificationPreferencesMutation, params);


export const verifyCurrentPassword = async (params) => await apiMainQuery(verifyCurrentPassword4, params);

export const checkIBANValidation = async (params) => await apiMainQuery(validateBankReferenceNumberV2Query, params);