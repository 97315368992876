import React from 'react'

const Bill = ({ height = 28, width = 28, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M6.75 6.08333H12.6M6.75 9.58333H10.6M16.5 18.5V6.03333C16.5 4.44652 16.5 3.65311 16.2343 3.04703C16.0006 2.5139 15.6277 2.08046 15.1691 1.80881C14.6477 1.5 13.9651 1.5 12.6 1.5H7.4C6.03487 1.5 5.35231 1.5 4.8309 1.80881C4.37225 2.08046 3.99936 2.5139 3.76567 3.04703C3.5 3.65311 3.5 4.44652 3.5 6.03333V18.5L5.73438 16.6111L7.76563 18.5L10 16.6111L12.2344 18.5L14.2656 16.6111L16.5 18.5Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Bill