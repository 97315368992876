import React from 'react';
import { parseArrFromString } from '../../../../shared/utility';
import Tabs from '../../components/Tabs/Tabs';

const Slider = ({ tabs }) => {
  const tabsContent = tabs ? parseArrFromString(tabs) : null;

  return (
    <Tabs data={tabsContent} slider={true} />
  )
};


export default Slider;
