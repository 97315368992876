import { bool, string } from 'prop-types';
import React from 'react';


const IncompleteStepIcon = ({
  // width,
  // height,
  disabled,
  outsideCircleColor,
  insideCircleColor,
  className
}) => {

  if (disabled) {
    insideCircleColor = '#F6F6F6';
    outsideCircleColor = '#E9E9E9';
  }

  return ( 
    <svg className={className} xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(1979.111 -14743.889)">
        <circle cx="12" cy="12" r="12" transform="translate(-1979.111 14743.889)"  fill={outsideCircleColor}/>
        <circle cx="8" cy="8" r="8" transform="translate(-1975.111 14747.889)"  fill={insideCircleColor}/>
        <circle cx="4" cy="4" r="4" transform="translate(-1971.111 14751.889)" fill="#fff"/>
    </g></svg>
);
}

IncompleteStepIcon.propTypes = {
  // width: number,
  // height: number,
  warning: bool,
  insideCircleColor: string,
  outsideCircleColor: string,
};

IncompleteStepIcon.defaultProps = {
  insideCircleColor: '#1060F8',
  //outsideCircleColor: 'rgba(17,96,247,0.4)',
  outsideCircleColor: 'rgba(152,187,255)',
};

export default IncompleteStepIcon;
