import React, { useState } from 'react';
import Box from '../../remitbee/components/box/Box';
import DropDown from '../../remitbee/components/dropDown/DropDown';
import DropDownItem from '../../remitbee/components/dropDown/DropDownItem';
import Flag from '../../remitbee/components/flag/Flag';
import Button from '../../remitbee/components/button/Button';
import Spacing from '../../remitbee/styles/spacing/Spacing';
import classes from './Services.module.scss'
import useCustomMediaQuery from '../../remitbee/customHook';
import theme from '../../remitbee/theme/Theme';

interface Props {
    t: any;
    onContinue?: any;
    countryId?: string;
    countries?: any;
    handleCountryChange?: any;
    flow?: string;
    label?: string;
    placeholder?: string;
    loading?: boolean;

}

const CountrySelection:React.FC<Props> = ({
    t,
    onContinue,
    countryId,
    countries,
    handleCountryChange,
    flow,
    label,
    placeholder,
    loading
}) => {
    const [selectedValue, setSelectedValue] = useState(countryId || '');
    const [countryList, setCountryList] = useState(countries);
    const isMobile = useCustomMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        const itemData = JSON.parse(e.currentTarget.getAttribute('data-item'));
        if (!itemData) return
        setSelectedValue(itemData.countryId);
        handleCountryChange(itemData);
    }

    const handleSearchChange = (value) => {
        if (!value) {
          setCountryList(countries)
        }
        else {
          const filterService = countries.filter((each) => each.name.toLowerCase().includes(value.toLowerCase()))
          filterService.length ? setCountryList(filterService) : setCountryList([]);
        }
      };

    const handleContinue = () => !selectedValue ? setError(true) : onContinue()

    const renderCountryList = (country) => {
        if (!country) return null;
        return (
          <DropDownItem
            key={country.countryId}
            value={country.countryId}
            data={country}
            onClick={handleChange}
          >
            <Box id={country.countryId} display="flex" alignItems="center">
              <Flag code={country.iso2} />
              <Box ml={3}>{country.name}</Box>
            </Box>
          </DropDownItem>
        );
    }  

    return (
        <>
           {onContinue && <Spacing variant='betweenSectionToInputFields' />}
            <Box style={{ width: '100%' }}>
                <DropDown
                 id="select-country-service"
                 label={flow ? t(`${flow}:recipient_details.recipient_country`) : label}
                 placeholder={flow ? t(`${flow}:recipient_details.select_country`) : placeholder}
                 required
                 selectedItem={countryList?.length && renderCountryList(countryList.find((country) => country.countryId === selectedValue))}
                 onSearch={handleSearchChange}
                 error={error && 'Required'}
                 list={countryList?.length && countryList.map((country) => renderCountryList(country))}
                />
            </Box>
            {onContinue && <Spacing variant='contentToCta' />}
            {onContinue && 
            <Box className={classes[isMobile ? 'rb-button-container' : '']}>
                <Button
                id="country-selection-continue"
                fullWidth
                disabled={loading}
                onClick={handleContinue}
            >
                {t(`${flow}:recipient_details.next_step`)}
              </Button>
            </Box>}
        </>
    )
}

export default CountrySelection