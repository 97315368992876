import { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react'
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TextField
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';


const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
    textAlign:'center',
    alignItems:'center',
    fontWeight:400,
    height:'60px',
    color:'#626680'
  },
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F9F8F7',
    },
    '&:last-child':{
        fontWeight:'bold'
    }
  },
}))(TableCell);


const useStyles = makeStyles((theme) => ({
  tab: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: '300px !important',
    borderBottom:'0.5px solid #CACCD5',
    color:'#31517A',
  },
  activeTab:{
    color: '#1160F7'
  },
  inputFields:{
    height:'56px'
  },
  root: {
    textAlign:'start',
    [theme.breakpoints.up('sm')]: {
      width:'600px',  
      maxWidth: '600px',
      minHeight:'590px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  paper: {
    width: '100%',
    border: '1px solid #CACCD5',
    borderRadius: '10px',
    minHeight:"590px"
  },
  calcContentContainer:{
    padding:'28px',
    [theme.breakpoints.down('sm')]: {
      padding:'16px'
    },
  },
  indicatorBtmLine: {
    outline: '1px solid #1160F7',
    backgroundColor: '#1160F7',
  },
  calcTitle: {
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '32px',
    color: '#31517A',
    fontFamily: 'Poppins',
    margin:'20px 0 20px 12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  gridContainer: {
    margin: '10px 0px 20px 0',
  },
  label: {
    size: '12px',
    fontWeight: 400,
    fontFamily: 'Heebo',
    color: '#626680',
    marginBottom: '10px',
  },
  dropDownColor: {
    backgroundColor: '#fff !important',
  },
  dropdownArrow: {
    marginRight: '5px',
  },
  tabsContainer:{
    height:'56px'
  },
  tableContainer: {
    minWidth: '280px',
  },
  dropDownDefault: {
    color:"#86868B"
  },
  boxPadding:{
    padding:'10px'
  },
  tableWrapper:{
    boxShadow:'none',
    border: "1px solid #CACCD5",
    borderRadius: "10px"
  }
}));


export default function GSTCalculator({provinces}) {
  const [input, setInput] = useState('');
  const [currentProvince, setCurrentProvince] = useState('');
  const [value, setValue] = useState('after_taxes');
  const classes = useStyles();

  const handleChange = (event) => {
    if(+event.target.value < 0) return;
    setInput(event.target.value);
  };

  const handleProvinceChange = (event) => {
    if(!event.target.value) return;
    let selectedProvince = JSON.parse(event.target.value);
    setCurrentProvince(selectedProvince);
  };

  const AmountCalc = (input, tax) => {
    let value = parseInt(input);
    let taxInput = parseInt(tax);
    return ((taxInput / 100) * value).toFixed(2);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };


  const CalcContentContainer = ({type}) => {
    let GST = input && currentProvince ? AmountCalc(input, currentProvince.GST) : 0;
    let PST = input && currentProvince ?  AmountCalc(input, currentProvince.PST) : 0;
    let HST = (parseFloat(GST) + parseFloat(PST)).toFixed(2);
    let TotalAmount = input && currentProvince ? type === 'after_taxes' ? (parseFloat(input) + parseFloat(HST)).toFixed(2) : (parseFloat(input) - parseFloat(HST)).toFixed(2) : 0;
    return (
        <>
          <Typography variant="h1" className={classes.calcTitle}>
              {type === 'after_taxes' ? "Amount after taxes" : "Amount before taxes"}
            </Typography>
            <Grid container className={classes.gridContainer}>
              <Grid item md={6} sm={6} xs={12}>
                <Box className={classes.boxPadding}>
                  <Typography variant="h3" className={classes.label}>
                    Amount
                  </Typography>
                  <TextField
                    InputProps={{
                        className: classes.inputFields,
                    }}
                    id="outlined-standard"
                    color="primary"
                    placeholder="Enter amount"
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    value={input}
                    autoFocus
                    type="number"   
                  />
                </Box>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Box className={classes.boxPadding}>
                  <Typography variant="h3" className={classes.label}>
                    Province
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      id="select-outlined"
                      value={currentProvince ? JSON.stringify(currentProvince) : currentProvince}
                      onChange={handleProvinceChange}
                      IconComponent={() => (
                        <KeyboardArrowDown className={classes.dropdownArrow} color="primary" />
                      )}
                      className={classes.inputFields}
                      classes={{ root: classes.dropDownColor }}
                      displayEmpty
                    >
                        <MenuItem className={classes.dropDownDefault} value=''>Select a Province</MenuItem>
                      {provinces?.map((area, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={JSON.stringify(area)}
                          >
                            {area.province}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Typography variant="h1" className={classes.calcTitle}>
              GST/HST Calculator
            </Typography>
            <TableContainer className={classes.tableWrapper} component={Paper}>
              <Table className={classes.tableContainer}>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>
                      <span>PST {`(${currentProvince ? currentProvince.PST : 0}%)`}</span>
                      <span>${PST}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>GST {`(${currentProvince ? currentProvince.GST : 0}%)`}</span>
                      <span>{`+ $${GST}`}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>HST {`(${currentProvince ? +currentProvince.GST + +currentProvince.PST : 0}%)`}</span>
                      <span>{`= $${HST}`}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>{type === 'after_taxes' ? "Total after taxes" : "Total before taxes"}</span>
                      <span>{`$${TotalAmount}`}</span>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
        </>
    )
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <TabContext value={value}>
          <TabList
            classes={{ indicator: classes.indicatorBtmLine,flexContainer:classes.tabsContainer }}
            onChange={handleChangeTab}
            aria-label="simple tabs example"
          >
            <Tab className={clsx(classes.tab,value === 'after_taxes' ? classes.activeTab : '')} label="After Taxes" value="after_taxes" />
            <Tab className={clsx(classes.tab,value === 'before_taxes' ? classes.activeTab : '')} label="Before Taxes" value="before_taxes" />
          </TabList>

          <TabPanel className={classes.calcContentContainer} value="after_taxes">
            <CalcContentContainer type="after_taxes"/>
          </TabPanel> 
          
          <TabPanel className={classes.calcContentContainer} value="before_taxes">
            <CalcContentContainer type="before_taxes" />
          </TabPanel>

        </TabContext>
      </Paper>
    </div>
  );
}

