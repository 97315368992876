import React from 'react';
import Section from './Section';
import { makeStyles } from '@mui/styles';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    box: {
        borderRadius: 4,
        padding: theme.spacing(2, 3)
    },
    text: {
        color: '#626680',
        fontSize: '16px',
        lineHeight: '24px'
    }
}));

const Disclaimer = ({ text, borderColor, backgroundColor }) => {
    const classes = useStyles();

    return (
        <Section>
            <div className={classes.box} style={{ border: `1px solid ${borderColor}`, backgroundColor }}>
                <span className={classes.text}><Markdown children={text} /></span>
            </div>
        </Section>
    )
}

Disclaimer.defaultProps = {
    text: '',
    borderColor: '#8C6B19',
    backgroundColor: '#fff'
}

Disclaimer.propTypes = {
    text: PropTypes.string,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string
}

export default Disclaimer;
