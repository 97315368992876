import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FlowContainer from '../../../common/FlowContainer';
import OperatorCard from '../../../giftCards-v2/OperatorSelection/components/OperatorCard';

const useStyles = makeStyles(theme => ({
    box: {
        gap: '24px',
        [theme.breakpoints.down('sm')]: {
            gap: '12px',
        },
    }
}));

const ChangeCarrier = ({
    t,
    flow,
    selectedOperator,
    setSelectedProduct,
    setSelectedOperator,
    operatorsAvailable,
    onContinue
}) => {
    const [selected, setSelected] = useState(selectedOperator);
    const classes = useStyles();

    const handleChange = () => {
        if (setSelectedOperator) setSelectedOperator(selected);
        if (setSelectedProduct) setSelectedProduct(null);
        if (onContinue) onContinue(selected);
    }

    return (
        <FlowContainer small>
            <h3 className='flowTitle'>{t(`${flow}:change_carrier.title`)}</h3>
            <Box className='flowBox'>
                <div className='text'>{t(`${flow}:change_carrier.description`)}</div>
                <Box display='flex' flexWrap="wrap" mt={5} fullWidth className={classes.box}>
                    {operatorsAvailable && operatorsAvailable.length && operatorsAvailable.map(operator => <OperatorCard
                            key={operator.id}
                            onChange={()=> setSelected(operator)}
                            selected={selected?.id === operator.id}
                            logo={operator.imageUrl}
                            label={operator.label}
                            altImage={'/services/topUpPlaceholder.svg'}
                        />
                    )}
                </Box>
            </Box>
            <Button
                fullWidth
                disabled={!selected}
                onClick={handleChange}
                variant="contained"
                color="primary"
                size="large"
            >
                {t(`${flow}:change_carrier.continue`)}
            </Button>
        </FlowContainer>
    );
};

export default ChangeCarrier;