import { Grid, Typography, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDown from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowUp from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
    arrayOf, bool, func, number, oneOf, oneOfType, shape,
    string
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import FlagIcon from '../FlagIcon';
import { connect } from 'react-redux';
import { getTransferRates } from '../../../redux/rates/actions';
import { fetchCountriesFromRates } from '../../../redux/system/actions';
import { useTranslation } from 'next-i18next';
import Button from '../Button';
import { snackbar } from '../Notifier';
import CountryList from './countryList/CountryList';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  flagIcon: {

  },
  countryCode: {
    marginLeft: theme.spacing(2),
  },
  arrowIcon: {
    marginLeft: '5px',
  },
  button: {
    minWidth: 150,
    height: 48,
    '&:hover': {
      background: '#E9F5FF',
    },
  },
  paper: {
    boxShadow: 0,
    background: 'transparent',
    border: '1px solid #CACCD5',
    borderRadius: 5,
    marginTop: theme.spacing(1)
  },
  list: {
    padding: 0,
    background: 'transparent'
  },
  arrow:{
    
  }
}));

const CountrySelectionDropdown = ({
  isDropdown,
  onChange,
  hasFlagIcon,
  dropdownStyle,
  menuStyle,
  defaultSelectedText,
  setDefaultFirstItemSelected,
  listTextOptions,
  selectedTextOptions,
  searchTextOptions,
  countries,
  fetchCountries,
  countriesList,
  selectedCountryId,
  currency
}) => {
  const defaultCountryCode = 'CA';
  const { t } = useTranslation('recipients')
  // If countries are passed as a props not from redux
  if (countriesList) countries = countriesList;
  if (countries && (typeof selectedCountryId === 'number' || typeof selectedCountryId === 'string')) {
    selectedCountryId = countries ? countries.find((element) => parseInt(element.country_to_id, 10) === selectedCountryId) : {};
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredItems, setFilteredItems] = useState(countries || []);
  const [selected, setSelected] = useState(selectedCountryId);
  const classes = useStyles();

  const handleMenu = (event) => {
    const target = event.currentTarget;
    setFilteredItems(countries)
    setAnchorEl(anchorEl ? null : target);
    // setTimeout(() => {
    //   setAnchorEl(anchorEl ? null : target);
    // });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (item) => {
    Promise.resolve().then(() => {
      handleClose();
      onChange(item);
      setSelected(item);
    });
  };

  const fetchData = async () => {
    const { data, errorMessage, success } = await getTransferRates();
    if (success) {
      const { TransferRatesOnline } = data;
      fetchCountries(TransferRatesOnline);
    } else snackbar.error(errorMessage);
  };

  useEffect(() => { if (!countries || countries.length === 0) fetchData(); }, []);

  useEffect(() => {
    if (setDefaultFirstItemSelected) setSelected(countries.find(value => value.id === 1));
    setFilteredItems(countries);
  }, [countries]);

  if (isDropdown) {
    let showText = '';
    let defaultFlagIcon = null;

    if (selected) {
      defaultFlagIcon = (
        <FlagIcon
          code={selected.iso2 || defaultCountryCode}
          className={classes.flagIcon}
        />
      );
      selectedTextOptions.forEach((text) => {
        showText += `${selected[text]} `;
      });
    }

    return (
      <div>
        <Button
          id="countryDropDownButton"
          variant="outlined"
          color="secondary"
          size="medium"
          className={classes.button}
          aria-label="directions"
          aria-owns={Boolean(anchorEl) === true ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleMenu}
          disableRipple
          style={dropdownStyle || {}}
          fullWidth
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={8} container direction="row" justifyContent="flex-start" alignItems="center">
              {defaultFlagIcon}
              {selected && <Typography variant="h3" className={classes.countryCode}>
                {showText}
              </Typography>}
              {!selected && <Typography variant="h3" className={classes.notSelected}>
                {t(defaultSelectedText)}
              </Typography>}
            </Grid>
            {Boolean(anchorEl) === false && countries && countries.length && <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
              <ArrowDown className={classes.arrowIcon} />
            </Grid>}
            {Boolean(anchorEl) && <ArrowUp className={classes.arrowIcon} />}
          </Grid>
        </Button>

        <Popover
          id="customized-menu"
          anchorEl={anchorEl}
          anchorReference={anchorEl}
          open={Boolean(anchorEl)}
          style={{ zIndex: 1300 }}
          elevation={0}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          PaperProps={{
            elevation: 4,
          }}
          classes={{ list: classes.list, paper: classes.paper }}
        >
          <CountryList
            open={Boolean(anchorEl)}
            currency={currency}
            menuStyle={menuStyle}
            countriesList={countries}
            filteredItems={filteredItems}
            onItemClick={onItemClick}
            setFilteredItems={setFilteredItems}
            options={listTextOptions}
            hasFlagIcon={hasFlagIcon}
            searchOptions={searchTextOptions}
          />
        </Popover>
      </div>
    );
  }

  return (
    <CountryList
      currency={currency}
      countriesList={countries}
      filteredItems={filteredItems}
      onItemClick={onItemClick}
      setFilteredItems={setFilteredItems}
      options={listTextOptions}
      searchOptions={searchTextOptions}
    />
  );
};

CountrySelectionDropdown.propTypes = {
  isDropdown: bool,
  onChange: func.isRequired,
  listTextOptions: arrayOf(
    oneOf(['currency_code', 'currency_name', 'country_to', 'country_name', 'country_from']),
  ),
  searchTextOptions: arrayOf(
    oneOf(['currency_code', 'currency_name', 'country_to', 'country_name', 'country_from' ]),
  ),
  selectedTextOptions: arrayOf(
    oneOf(['currency_code', 'currency_name', 'country_to', 'country_name', 'country_from' ]),
  ),
  hasFlagIcon: bool,
  dropdownStyle: shape({}),
  menuStyle: shape({}),
  defaultSelectedText: string,
  setDefaultFirstItemSelected: bool,
  countries: arrayOf(shape({
    id: oneOfType([number, string]),
    country_to: string,
    feed_rate: string,
    currency_name: string,
    currency_code: string,
    iso3: string,
    iso2: string,
    favorite: bool,
  })),
  fetchCountries: func.isRequired,
  selectedCountryId: oneOfType([number, string]),
};

CountrySelectionDropdown.defaultProps = {
  isDropdown: true,
  hasFlagIcon: true,
  listTextOptions: ['country_to', 'currency_code'],
  searchTextOptions: [],
  selectedTextOptions: ['currency_code'],
  dropdownStyle: {},
  menuStyle: {},
  defaultSelectedText: 'select_country',
  setDefaultFirstItemSelected: true,
  countries: null,
  selectedCountryId: null,
};

const mapStateToProps = ({ system }) => ({
  countries: system.countries || [],
});

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: () => dispatch(fetchCountriesFromRates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelectionDropdown);
